import { ScanAnalysisStepViewModel, 
  SingleScanApplicationTaskTemplate, 
  SingleScanApplicationTaskTemplateApplicationStepTemplate} from "../../../app/api/aiq-api";
import { ClientSideBaseAnalysisStepViewModel } from "./ClientSideBaseAnalysisStepViewModel";
import { ClientSideSingleScanProcessingTaskViewModel } from "./ClientSideSingleScanAnalysisTaskViewModel";
import { ClientSideSingleScanAnalysisViewModel } from "./ClientSideSingleScanAnalysisViewModel";


export class ClientSideSingleScanAnalysisStepViewModel extends ClientSideBaseAnalysisStepViewModel<SingleScanApplicationTaskTemplate> {
  constructor(backendViewModel: ScanAnalysisStepViewModel, parentScanAnalysisViewModel: ClientSideSingleScanAnalysisViewModel) {
    super(backendViewModel);

    var stepTemplate = parentScanAnalysisViewModel.applicationTemplate.steps?.find((s: SingleScanApplicationTaskTemplateApplicationStepTemplate) => s.systemId === backendViewModel.scanAnalysisStep!.applicationStepSystemId);
    if(!stepTemplate) {
      throw new Error(`Can't construct Client Side Scan Analysis Step View Model for ${backendViewModel.scanAnalysisStep?.id} - no step template with system ID ${backendViewModel.scanAnalysisStep!.applicationStepSystemId} found in application template ${parentScanAnalysisViewModel.applicationTemplate.systemId}`);
    }

    this.stepTemplate = stepTemplate;

    this.parentSingleScanAnalysisViewModel = parentScanAnalysisViewModel;
    this.tasks = backendViewModel.tasks!.map(task => new ClientSideSingleScanProcessingTaskViewModel(task, this));
  }

  parentSingleScanAnalysisViewModel!: ClientSideSingleScanAnalysisViewModel;
  stepTemplate!: SingleScanApplicationTaskTemplateApplicationStepTemplate;


  protected override checkRequiredFieldsForNull(backendViewModel: ScanAnalysisStepViewModel) {
    // There is probably a super fancy way to do this but everything I googled looked ugly and unnecessarily complicated 
    // so I went with dumb but simple, like myself - CSS20220315
    if(!backendViewModel.scanAnalysisStep) {
      this.throwNullArgConstructionError('scanAnalysisStep', backendViewModel)
     }
     if(!backendViewModel.tasks) {
      this.throwNullArgConstructionError('tasks', backendViewModel)
     }
  }

  private throwNullArgConstructionError(nullFieldName: string, backendViewModel: ScanAnalysisStepViewModel) {
    throw new Error(`Can't construct client side Scan Analysis Step View Model for ${backendViewModel.scanAnalysisStep?.id} - required field ${nullFieldName} is null or empty`);
  }
}
