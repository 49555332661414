import * as React from "react";
import { Tabs, Tab, Backdrop, CircularProgress, useTheme } from "@mui/material";
import { ScanAnalysisStep } from "./ScanAnalysisStep";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { ClientSideSingleScanAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisStepViewModel";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { ClientSideInterscanAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisStepViewModel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";
import { ClientSideCompoundInterscanAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideCompoundInterscanAnalysisStepViewModel";

type IScanAnalysisStepsProps = {
  scanAnalysisVM: ClientSideSingleScanAnalysisViewModel | ClientSideInterscanAnalysisViewModel |  ClientSideCompoundInterscanAnalysisViewModel | undefined;
  showNittyGrittyDetails: boolean;
  onForceRefresh: () => void;
};

export const ScanAnalysisSteps: React.FunctionComponent<IScanAnalysisStepsProps> = (props) => {
  const [selectedStepSystemId, setSelectedStepSystemId] = React.useState<string | undefined>(undefined);
  const theme = useTheme();

  React.useEffect(() => {
    setSelectedStepSystemId(
      props.scanAnalysisVM?.orderedScanAnalysisSteps[props.scanAnalysisVM?.orderedScanAnalysisSteps.length -1].scanAnalysisStep.applicationStepSystemId ?? undefined
    );
  }, []);

  const handleStepSelection = (stepSystemId: string) => {
    setSelectedStepSystemId(stepSystemId);
  };

  const getSelectedStep = ():
    ClientSideSingleScanAnalysisStepViewModel | ClientSideInterscanAnalysisStepViewModel | ClientSideCompoundInterscanAnalysisStepViewModel | undefined => {
    const steps:
      Array<ClientSideSingleScanAnalysisStepViewModel | ClientSideInterscanAnalysisStepViewModel | ClientSideCompoundInterscanAnalysisStepViewModel> | undefined =
      props.scanAnalysisVM?.orderedScanAnalysisSteps;
    const result = steps?.find(x => x.scanAnalysisStep.applicationStepSystemId === selectedStepSystemId);
    return result;
  };

  const renderStepTab = (step: ClientSideSingleScanAnalysisStepViewModel | ClientSideInterscanAnalysisStepViewModel) => {
    return (
      <Tab
        data-cy="scan-analysis-step-tab"
        key={step.stepTemplate.systemId!}
        label={step.stepTemplate.displayName}
        sx={{
          fontSize: 11,
          minHeight: "inherit",
        }}
        value={step.stepTemplate.systemId!}
        icon={step.hasWarnings ? (<WarningAmberIcon sx={{ fontSize: 11, color: theme.palette.warning.main }} />) : undefined}
        iconPosition={step.hasWarnings ? "end" : undefined}
      />
    );
  }

  return (
    <React.Fragment>
      <Backdrop sx={{ zIndex: theme.zIndex.drawer + 1,  color: "#fff",}} open={!getSelectedStep()}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {getSelectedStep() && (
        <React.Fragment>
          <Tabs
            value={selectedStepSystemId}
            onChange={(event, value) => handleStepSelection(value)}
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
          >
            {props.scanAnalysisVM?.orderedScanAnalysisSteps.map((step) => renderStepTab(step))}
          </Tabs>
          <ScanAnalysisStep
            onForceRefresh={() => props.onForceRefresh()}
            showNittyGrittyDetails={props.showNittyGrittyDetails}
            step={getSelectedStep()!}
            scanAnalysis={props.scanAnalysisVM!}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
