import { ApplicationState } from "../../redux/store/ConfigureStore";
import { PlatformRole, PlatformUser } from "../api/aiq-api";
import { enhancedApi } from "../api/enhancedApi";

import { UseQueryResult } from "../api/useQueryResult";
import { createSelector } from "@reduxjs/toolkit";
import { AuthState } from "../slices/authSlice";

/**
 * A redux state selector that returns a boolean indicating whether the user is Authenticated
 */
export const IsAuthenticatedSelector = createSelector<any, boolean>(
  (state: AuthState) => state.token,
  (token: string | null) => token !== null
);

/**
 * A redux state selector returning a boolean indicating whether data about the current
 * user is in the process of loading 
 */
export const CurrentUserDataIsLoadingSelector = createSelector<any, boolean>(
  (state: ApplicationState) => {
    return enhancedApi.endpoints.getApiPlatformUsersSelf.select()(state);
  },
  (state: ApplicationState) => {
    const currentUser1 = enhancedApi.endpoints.getApiPlatformUsersSelf.select()(state);
    return enhancedApi.endpoints.getApiPlatformUsersByIdRoles.select({
      id: currentUser1.data?.id ?? "",
    })(state);
  },
  (
    userResult: UseQueryResult<PlatformUser>,
    rolesResult: UseQueryResult<Array<PlatformRole>>
  ) => userResult.isLoading || rolesResult.isLoading
);

/**
 * A redux state selector returning the currently logged in PlatformUser, or undefined 
 * if no user is logged in
 */
export const CurrentUserSelector = createSelector<any, PlatformUser | undefined>(
  (state: ApplicationState) => {
    return enhancedApi.endpoints.getApiPlatformUsersSelf.select()(state);
  },
  (userResult: UseQueryResult<PlatformUser>) => userResult.data
);

/**
 * A redux state selector returning a boolean indicating whether the current user,
 * if any, is an Admin
 */
export const CurrentUserIsAdminSelector = createSelector<any, boolean>(
  (state: ApplicationState) => {
    const currentUser1 = enhancedApi.endpoints.getApiPlatformUsersSelf.select()(state);
    return enhancedApi.endpoints.getApiPlatformUsersByIdRoles.select({
      id: currentUser1.data?.id ?? "",
    })(state);
  },
  (result: UseQueryResult<Array<PlatformRole>>) => {
    return (
      result.data !== undefined &&
      result.data.find((role) => role.platformRoleName === "AiqAdmin") !== undefined
    );
  }
);

export const CurrentUserIsAnalystSelector = createSelector<any, boolean>(
  (state: ApplicationState) => {
    const currentUser1 = enhancedApi.endpoints.getApiPlatformUsersSelf.select()(state);
    return enhancedApi.endpoints.getApiPlatformUsersByIdRoles.select({
      id: currentUser1.data?.id ?? "",
    })(state);
  },
  (result: UseQueryResult<Array<PlatformRole>>) => {
    return (
      result.data !== undefined &&
      result.data.find((role) => role.platformRoleName === "Analyst") !== undefined
    );
  }
);

export const CurrentUserIsReviewerSelector = createSelector<any, boolean>(
  (state: ApplicationState) => {
    const currentUser1 = enhancedApi.endpoints.getApiPlatformUsersSelf.select()(state);
    return enhancedApi.endpoints.getApiPlatformUsersByIdRoles.select({
      id: currentUser1.data?.id ?? "",
    })(state);
  },
  (result: UseQueryResult<Array<PlatformRole>>) => {
    return (
      result.data !== undefined &&
      result.data.find((role) => role.platformRoleName === "Reviewer") !== undefined
    );
  }
);

export const authSelectors = { 
  IsAuthenticatedSelector, 
  CurrentUserIsAdminSelector, 
  CurrentUserSelector,
  CurrentUserDataIsLoadingSelector 
};
