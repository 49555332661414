import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

interface ConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  text: string;
  title: string;
  confirmButtonLabel: string;
}

/**
 * This is a little functional component to show a confirmation dialog
 * @param props open, text, title, onConfirm, onCancel
 */
export const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = (
  props: ConfirmationDialogProps
) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.onCancel()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onCancel()} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => props.onConfirm()}
          variant="contained"
          color="primary"
          autoFocus
        >
          {props.confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
