import * as React from "react";
import { Typography, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Box } from "@mui/material";
import { getFormattedDateFromString } from "../../app/utilities/dateUtilities";
import WarningIcon from '@mui/icons-material/Warning';
import { AiqTooltip } from "../common/AiqToolTip";

interface IInterscanSingleScanGroupTableProps {
  data: Array<Array<string | undefined | null>>;
  warnings: Array<string>;
  headCells: Array<any>;
  keyRowIndex: number;
  maxCapacity: number;
}


export const InterscanSingleScanGroupTable: React.FunctionComponent<IInterscanSingleScanGroupTableProps> = (props) => {
  return (
    <Paper sx={{width: "100%", height: "100%", mb: 2}}>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              {props.headCells.map((headCell, index) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  sx={{minWidth: headCell.width, maxWidth: headCell.width, paddingLeft: 1, paddingRight: 1, fontSize: ".75rem" }}
                  >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.length == 0 && (
              <TableRow>
                <TableCell colSpan={props.headCells.length}>
                  <Typography variant="subtitle2" color="secondary" textAlign="center" margin={1}>Add Single Scan{props.maxCapacity > 1 ? "s" : ""}</Typography>
                </TableCell>
              </TableRow>
            )}
            {props.data.map((row, index) => {
              const isItemSelected = false;

              return (
                <TableRow
                  hover
                  data-cy="SortableTableRow"
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row[props.keyRowIndex]}
                  selected={isItemSelected}
                >
                  {row.map((cell, index: number) =>                      
                    <AiqTooltip enterDelay={1500} enterNextDelay={500} title={props.headCells[index].date ? getFormattedDateFromString(cell): cell}>
                      <TableCell
                        key={index}
                        align={props.headCells[index].numeric ? 'right' : 'left'}
                        sx={{fontSize: ".75rem", padding: "6px 4px", minWidth: props.headCells[index].width, maxWidth: props.headCells[index].width, overflow: "hidden", textOverflow: "ellipsis"}}
                        >
                          {props.headCells[index].date ? getFormattedDateFromString(cell): cell}
                      </TableCell>
                    </AiqTooltip>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.warnings.map((warning, i) => (
        <Box 
          key={i}
          data-cy="scan-group-warnings-container"
          sx={{
            px: .5, 
            py: .15,
            color: "orange",
            alignItems: "center",
            }}
        >
        <Typography variant="subtitle2" fontSize={".75rem"}>
          <WarningIcon fontSize="inherit" sx={{mx: 1}}/>
          {warning}
        </Typography>
        </Box>
      ))}
    </Paper>
  )
}

