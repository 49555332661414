import {  InterscanAnalysisViewModel, InterscanApplication, InterscanAnalysis, InterscanApplicationTaskTemplateApplicationStepTemplate } from "../../../app/api/aiq-api";
import { ClientSideInterscanAnalysisStepViewModel } from "./ClientSideInterscanAnalysisStepViewModel";
import { ClientSideSingleScanAnalysisViewModel } from "./ClientSideSingleScanAnalysisViewModel";
import { ClientSideBaseAnalysisViewModel } from "./ClientSideBaseScanAnalysisViewModel";


/**
 * A client-side view model for displaying UIs for an Interscan Analysis
 */
export class ClientSideInterscanAnalysisViewModel extends ClientSideBaseAnalysisViewModel {
  constructor(backendViewModel: InterscanAnalysisViewModel) {
    super(backendViewModel);
    this.checkRequiredFieldsForNull(backendViewModel);
    this.sourceScanAnalysisGroups = backendViewModel.sourceScanAnalysisVMs?.map(x => x.map(y => new ClientSideSingleScanAnalysisViewModel(y))) ?? [[]];
    this.applicationTemplate = backendViewModel.interscanAnalysisApplication!;
    this.interscanAnalysis = backendViewModel.interscanAnalysis!;
    this.orderedScanAnalysisSteps = backendViewModel.orderedScanAnalysisSteps!.map(step => new ClientSideInterscanAnalysisStepViewModel(step, this));
  }

  sourceScanAnalysisGroups!: Array<Array<ClientSideSingleScanAnalysisViewModel>>;
  orderedScanAnalysisSteps!: Array<ClientSideInterscanAnalysisStepViewModel>;
  interscanAnalysis: InterscanAnalysis;
  applicationTemplate!: InterscanApplication;

  /**
   * The step that this scan analysis can automatically move on to
   */
  automatedSuccessorComponentId: string | undefined;

  get isGrouped(): boolean {
    return this.sourceScanAnalysisGroups.filter(x => x.length > 1).length > 0;
  }

  get dateCreated(): Date {
    return new Date(this.interscanAnalysis.initiatedDate!);
  }

  get interscanProcessingIsComplete(): boolean {
    return this.currentStepComplete && this.currentStep == this.orderedScanAnalysisSteps[this.orderedScanAnalysisSteps.length - 1];
  }

  protected isStepAutomated(stepSystemId: string): boolean {
    var step: InterscanApplicationTaskTemplateApplicationStepTemplate | undefined = 
      this.applicationTemplate.steps?.find((s: InterscanApplicationTaskTemplateApplicationStepTemplate) => s.systemId === stepSystemId);
    return step?.automated ?? false;
  }

  protected checkRequiredFieldsForNull(backendViewModel: InterscanAnalysisViewModel) {
    // There is probably a super fancy way to do this but everything I googled looked ugly and unnecessarily complicated 
    // so I went with dumb but simple, like myself - CSS20220315
    if(!backendViewModel.sourceScanAnalyses || backendViewModel.sourceScanAnalyses.length === 0) {
      this.throwNullArgConstructionError('sourceScanAnalyses', backendViewModel)
     }
     if(!backendViewModel.orderedScanAnalysisSteps || backendViewModel.orderedScanAnalysisSteps.length == 0) {
      this.throwNullArgConstructionError('orderedScanAnalysisSteps', backendViewModel)
     }
     if(!backendViewModel.study) {
      this.throwNullArgConstructionError('study', backendViewModel)
     }
     if(!backendViewModel.interscanAnalysis) {
      this.throwNullArgConstructionError('interscanAnalysis', backendViewModel)
     }
  }

  private throwNullArgConstructionError(nullFieldName: string, backendViewModel: InterscanAnalysisViewModel) {
    throw new Error(`Can't construct client side Interscan Analysis View Model for ${backendViewModel.interscanAnalysis?.id} - required field ${nullFieldName} is null or empty`);
  }
}
