import { combineReducers } from "redux";
import { reducers } from "./index";
import { enhancedApi } from "../../app/api/enhancedApi";
import { configureStore } from "@reduxjs/toolkit";

export function configureAiqStore(initialState?: any) {
  // Combine all the reducers we want to use into a single list
  const rootReducer = combineReducers({
    ...reducers, // our collection of Reducers
    [enhancedApi.reducerPath]: enhancedApi.reducer
  });

  const store = configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(enhancedApi.middleware),
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./index", () => {
        store.replaceReducer(rootReducer);
    });
  }

  return store;
}

export const store = configureAiqStore();
export type ApplicationState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
