import * as React from "react";
import { Grid, Typography, Theme, Paper, useTheme, Box } from "@mui/material";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { getFormattedDateFromString } from "../../app/utilities/dateUtilities";

/**
 * This interface defines the shape of the data this component expects for its props.
 */
interface ISingleScanProcessingDetailsProps {
  scanAnalysisVM: ClientSideSingleScanAnalysisViewModel | undefined;
  showNittyGrittyDetails: boolean;
}

export const SingleScanAnalysisDetails: React.FunctionComponent<ISingleScanProcessingDetailsProps> = (
  props
) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      {props.scanAnalysisVM && (
        <Paper data-cy="ScanAnalysisDetails" sx={{ width: "100%", marginBottom: "10px" }}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} sm={1}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column" as "column",
                  alignItems: "baseline",
                  padding: theme.spacing(2),
                  color: theme.palette.primary.light,
                }}
              >
                <Typography variant="h6" sx={{ mr: 1 }} gutterBottom>
                  Details
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={11}>
              <Grid sx={{ padding: "10px" }} container spacing={1} alignItems="flex-start">
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Study:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap" as "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.scanAnalysisVM.study.studyName}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.scanAnalysisVM.study.id}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Application:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={8}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap" as "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.scanAnalysisVM.applicationTemplate.displayName}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.scanAnalysisVM.applicationTemplate.systemId}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Patient:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap" as "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.scanAnalysisVM.patient.patientIdentifier}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.scanAnalysisVM.patient.id}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Exam:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap" as "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {getFormattedDateFromString(props.scanAnalysisVM.exam.examDate!)}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.scanAnalysisVM.exam.id}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Timepoint:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={9}
                  md={5}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap" as "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.scanAnalysisVM.timepoint.timepointName}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.scanAnalysisVM.timepoint.id}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    CT Scan:
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={10} md={2}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap" as "wrap",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: 14,
                          marginRight: "4px",
                        }}
                      >
                        {props.scanAnalysisVM.anatomicalScanViewModel.scanLabel}
                      </Typography>
                      {props.showNittyGrittyDetails && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: 10,
                            color: theme.palette.secondary.dark,
                            textAlign: "left",
                            padding: "2px",
                          }}
                        >
                          {props.scanAnalysisVM.anatomicalScanViewModel.id}
                        </Typography>
                      )}
                    </Grid>
                    {props.showNittyGrittyDetails && (
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: 10,
                            color: theme.palette.secondary.dark,
                            textAlign: "left",
                            padding: "2px",
                            marginRight: "4px",
                          }}
                        >
                          {props.scanAnalysisVM.anatomicalScanViewModel.rawDataLocation}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {props.scanAnalysisVM.functionalScanViewModel && (
                  <React.Fragment>
                    <Grid item xs={3} sm={2} md={1}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textAlign: "right",
                          marginRight: "2px",
                          fontSize: 14,
                          color: theme.palette.primary.light,
                        }}
                      >
                        PET Scan:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm={10} md={3}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap" as "wrap",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: 14,
                              marginRight: "4px",
                            }}
                          >
                            {props.scanAnalysisVM.functionalScanViewModel.scanLabel}
                          </Typography>
                          {props.showNittyGrittyDetails && (
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: 10,
                                color: theme.palette.secondary.dark,
                                textAlign: "left",
                                padding: "2px",
                              }}
                            >
                              {props.scanAnalysisVM.functionalScanViewModel.id}
                            </Typography>
                          )}
                        </Grid>
                        {props.showNittyGrittyDetails && (
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: 10,
                                color: theme.palette.secondary.dark,
                                textAlign: "left",
                                padding: "2px",
                                marginRight: "4px",
                              }}
                            >
                              {props.scanAnalysisVM.functionalScanViewModel?.rawDataLocation}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
};
