import { createAction } from "typesafe-actions";


/**
 * This action sets the ID of a currently viewed study in the global state
 */
export const setViewedStudyIdAction = createAction("SET_VIEWED_STUDY")<string>();

/**
 * This action sets the Filter controllinng which Applications are visible in the Single Scan Processing Dashboard
 */
export const setStudyEditApplicationFilterId = createAction("SET_STUDY_EDIT_APPLICATION_FILTER")<string>();

