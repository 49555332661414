import { Typography, Link } from "@mui/material";
import React from "react";

/**
 * A simple functional component that displays our copyright footer
 */
export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://aiq-solutions.com/" underline="hover">
        AIQ Solutions
      </Link>
      {". "}
      All Rights Reserved.
    </Typography>
  );
};
