import moment from "moment";

export const defaultDateFormat = "YYYY-MM-DD";
export const dateFormatWithTime = "YYYY-MM-DD HH:mm";

/**
 * Simple method to format dates from date strings, to keep the logic centralized
 * @param dateString The date to format, in string form
 * @param dateFormat The format string defining the date's format. Optional - defaults to MM/DD/YYYY
 * @returns A date string formatted
 */
export function getFormattedDateFromString(
    dateString: string | undefined | null,
    dateFormat: string = defaultDateFormat
): string {
    return dateString ? moment(dateString).local().format(dateFormat).toUpperCase() : "";
}