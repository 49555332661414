import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Paper, Backdrop, CircularProgress, useTheme } from "@mui/material";
import { DragDropContext, DropResult, DragStart } from "react-beautiful-dnd";
import { InterscanProcessingLane } from "./InterscanProcessingLane";
import { ScanProcessingConstants } from "../../constants/ScanProcessingConstants";
import { Toast } from "../common/Toast";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { InterscanApplication } from "../../app/api/aiq-api";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { useSelector } from "react-redux";
import { ClientSideInterscanAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisStepViewModel";
import { SelectedStudyInterscanAnalysisVMsSelector } from "../../app/selectors/interscanAnalysisSelectors";
import { enhancedApi } from "../../app/api/enhancedApi";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";

// Derive the AppProps type info
interface IInterscanProcessingLanesProps {
  application: InterscanApplication,
  onStepTransition: () => void
}

/**
 * This is a connected component that displays a series of lanes representing a step in interscan analysis
 */
export const InterscanProcessingLanes: React.FunctionComponent<IInterscanProcessingLanesProps> = (props) => {
  const [draggedScanAnalysis, setDraggedScanAnalysis] = React.useState<ClientSideInterscanAnalysisViewModel | undefined>(undefined);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [doTransition, transitionResult] = enhancedApi.endpoints.postApiScanAnalysisStepsByIdTransition.useMutation();
  const interscanAnalysisVMs = useSelector(SelectedStudyInterscanAnalysisVMsSelector);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleCardSelected = (card: ClientSideInterscanAnalysisViewModel) => {
    navigate(`${NavigationConstants.interscanAnalysisPath}/${card.interscanAnalysis?.id}`);
  }

  const onDragStart = (start: DragStart) => {
    var draggedCard = interscanAnalysisVMs?.find(
      (card: ClientSideInterscanAnalysisViewModel) => card.interscanAnalysis?.id === start.draggableId
    );
    setDraggedScanAnalysis(draggedCard);
  }

  const scanAnalysisStepById = (stepId: string): ClientSideInterscanAnalysisStepViewModel | undefined => {
    var allSteps =  interscanAnalysisVMs.flatMap(savm => savm.orderedScanAnalysisSteps);
    return allSteps.find(step => step.scanAnalysisStep.id === stepId)
  }

  React.useEffect(() => {
    var targetStepTemplateSystemId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.targetStepSystemId ?? "Unknown";
    var originStepId = transitionResult.originalArgs?.scanAnalysisStepTransitionRequest.currentStepId ?? "Unknown";
    var originStepVM = scanAnalysisStepById(originStepId);
    var targetStepVM = originStepVM?.parentInterscanAnalysisViewModel?.applicationTemplate?.steps?.find(step => step.systemId === targetStepTemplateSystemId);
    if(transitionResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(transitionResult.error);
      setSnackbarText(
        `FAILED to Transition Interscan Analysis ${originStepVM!.parentInterscanAnalysisViewModel.interscanAnalysis.id} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName} - ${errorMessage}`
      );  
    } else if(transitionResult.isSuccess ) {
      setSnackbarText(
        `Transitioned Interscan Analysis ${originStepVM!.parentInterscanAnalysisViewModel.interscanAnalysis.id} from 
        ${ originStepVM?.stepTemplate.displayName } to ${targetStepVM?.displayName}`
      );
      props.onStepTransition();
    }
  }, [transitionResult])
  

  const onDragEnd = (result: DropResult) => {
    let destinationStep = props.application.steps?.find(
      (step) => step.systemId === result.destination?.droppableId
    );
    if (destinationStep) {
      var currentStep = draggedScanAnalysis!.currentStep;
      try {
        var currentStepId: string = currentStep.scanAnalysisStep.id!

        var transitionRequest = {
          currentStepId: currentStepId,
          targetStepSystemId: destinationStep.systemId!,
        };
        doTransition({id: currentStepId, scanAnalysisStepTransitionRequest: transitionRequest})
      } catch (error) {
        let errMesg = `Failed to transition Scan Analysis ${
          draggedScanAnalysis!.interscanAnalysis.id
        } from ${ currentStep.stepTemplate.displayName } to ${destinationStep.displayName}: ${error instanceof Error ?  error.message : "unknown"}`;
        setSnackbarText(errMesg);
        console.error(errMesg);
      }
    } else {
      console.error(`Failed to identify destination component`);
    }
    setDraggedScanAnalysis(undefined);
    return;
  }

  const renderLanes = () => {
    return (
      <React.Fragment>
        {<Backdrop
          sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff"}}
          open={transitionResult.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result)}
          onDragStart={(start) => onDragStart(start)}
        >
          <div style={{ display: "inline-block"}}>
            <Paper sx={{
              display: "flex",
              marginBottom: "20px",
              justifyContent: "flex-start"
            }}
            data-cy="InterscanProcessingLanes">
              {props.application.steps?.map((step) => (step.systemId !== ScanProcessingConstants.stepSystemIds.scanIngest && (
                <Container
                  key={step.systemId}
                  disableGutters={true}
                  sx={{
                    width: 275,
                    display: "flex",
                    margin: 0
                  }}
                >
                  <InterscanProcessingLane
                    stepTemplate={step}
                    draggedScanAnalysis={draggedScanAnalysis}
                    cardSelected={(card: ClientSideInterscanAnalysisViewModel) => {
                      handleCardSelected(card);
                    }}
                    interscanAnalysisVMs={interscanAnalysisVMs?.filter((card) => 
                      card.applicationTemplate.systemId === props.application.systemId &&
                      card.currentStep.stepTemplate.systemId === step.systemId
                      )}
                  />
                </Container>)
              ))}
            </Paper>
          </div>
        </DragDropContext>
      </React.Fragment>
    );
  }
    return (
      <React.Fragment>
        {props.application && renderLanes()}
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ""}
            autoHideDurationMillisecs={6000}
          />
      </React.Fragment>
    );


}


