import * as React from "react";
import { Typography, Theme, FormControl, MenuItem, Select, InputLabel, SelectChangeEvent } from "@mui/material";
import { Study } from "../../app/api/aiq-api";

export interface IStudySelectMenuProps {
  onSelectionChanged: (studyId: string | undefined) => void;
  selectedStudy: Study | undefined;
  nullSelectLabel?: string;
  studies: Array<Study>;
}

/**
 * A simple functional component that displays a menu for selecting studies
 */
export const StudySelectMenu: React.FunctionComponent<IStudySelectMenuProps> = (
  props
) => {
  return (
    <FormControl variant="standard" data-cy="StudySelectMenu" sx={{ width: "100%"}}>
      <InputLabel id="study-select-label">Study</InputLabel>
      <Select
        labelId="study-select-label"
        id="study-select"
        value={props.selectedStudy?.id!}
        onChange={(event: SelectChangeEvent) => {
          var value = event.target.value as string | undefined;
          if (value === "ANY") {
            value = undefined;
          }
          props.onSelectionChanged(value);
        }}
      >
        {[...props.studies]
          .sort((a: Study, b: Study) => {
            if (
              (a.studyName as string).toLocaleLowerCase() <
              (b.studyName as string).toLocaleLowerCase()
            ) {
              return -1;
            }
            if (
              (a.studyName as string).toLocaleLowerCase() >
              (b.studyName as string).toLocaleLowerCase()
            ) {
              return 1;
            }
            return 0;
          })
          .map((study: Study) => (
            <MenuItem value={study.id} key={study.id}>
              <Typography variant="subtitle1">{study.studyName}</Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
