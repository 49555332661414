import { Study } from "../../app/api/aiq-api";
import {
  IconButton,
  Grid,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import React from "react";
import { Delete, PlaylistPlay } from "@mui/icons-material";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { AiqTooltip } from "../common/AiqToolTip";
import { getFormattedDateFromString } from "../../app/utilities/dateUtilities";

interface IStudyListProps {
  studies: Array<Study>;
  studyDeleteClicked: (studyToDeleteId: string) => void;
  studyProcessingClicked: (studyToProcess: string) => void;
}

interface IStudyListState {
  viewedStudies: Array<Study>;
}

/**
 * A functional component to display a list of Studies
 * @param props The input props holding the collection of Studies to list
 */
class StudyListComponent extends React.PureComponent<IStudyListProps, IStudyListState> {
  constructor(props: IStudyListProps) {
    super(props);
    this.state = { viewedStudies: this.props.studies } as IStudyListState;
  }

  componentDidUpdate(prevProps: IStudyListProps) {
    if (
      prevProps.studies.length !== this.props.studies.length ||
      this.state.viewedStudies.length === 0
    ) {
      this.setState({ viewedStudies: this.props.studies });
    }
  }

  render() {
    const sortedStudies = [...this.props.studies].sort((a, b) => (a.studyName ?? "").localeCompare(b.studyName ?? ""));

    return (
      <TableContainer component={Paper} style={{maxHeight: "100%"}} >

          <Table stickyHeader aria-labelledby="tableLabel">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>ID</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedStudies.map((study: Study) => (
                <TableRow key={study.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={`${NavigationConstants.studiesPath}/${study.id}`}
                      underline="hover">
                      <div>{study.studyName}</div>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={`${NavigationConstants.studiesPath}/${study.id}`}
                      underline="hover">
                      <div> <span>{getFormattedDateFromString(study.createdDate)}</span></div>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={`${NavigationConstants.studiesPath}/${study.id}`}
                      underline="hover">
                      <div>{study.id}</div>
                    </Link>
                  </TableCell>

                  <TableCell>
                  <Grid container>
                  <Grid item xs={2}>
                    <AiqTooltip title="Study Processing">
                      <IconButton
                        data-cy="StudyListProcessingButton"
                        size="small"
                        color="primary"
                        aria-label="Study Processing"
                        onClick={(event) => {
                          this.props.studyProcessingClicked(study.id!);
                          event.stopPropagation();
                        }}
                      >
                        <PlaylistPlay />
                      </IconButton>
                    </AiqTooltip>
                  </Grid>
                  <Grid item xs={1}>
                    <AiqTooltip title="Delete Study">
                      <IconButton
                        data-cy="StudyListDeleteButton"
                        size="small"
                        color="primary"
                        aria-label="Delete Study"
                        onClick={(event) => {
                          this.props.studyDeleteClicked(study.id!);
                          event.stopPropagation();
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </AiqTooltip>
                  </Grid>
                </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </TableContainer>
    );
  }
}

export const StudyList = StudyListComponent;
