import { Typography, Grid, Card, useTheme, Box } from "@mui/material";

import React from "react";
import { getFormattedDateFromString, dateFormatWithTime } from "../../../app/utilities/dateUtilities";
import { ScanProcessingStatusIndicator } from "../ScanProcessingStatusIndicator";
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";
import { InterscanProcessingCard } from "../InterscanProcessingCard";

interface ICompoundInterscanProcessingCardProps {
  compoundInterscanAnalysisVM: ClientSideCompoundInterscanAnalysisViewModel;
}

/**
 * This component displays a card on the Interscan Processing dashboard representing
 * the state of processing on an Interscan Analysis
 */
export const CompoundInterscanProcessingCard: React.FunctionComponent<ICompoundInterscanProcessingCardProps> = (props) => {
  const theme = useTheme();

  const getStripeColor = (): string => {

    // Priority for stripe color: Error > Warning > Everything else
    if (props.compoundInterscanAnalysisVM.currentStepHasError) {
      return theme.palette.error.light;
    }

    if (props.compoundInterscanAnalysisVM.currentStepHasWarnings) {
      return theme.palette.warning.main;
    }

    if (props.compoundInterscanAnalysisVM.canMoveToNextStep) {
      return theme.palette.success.light;
    }

    if (props.compoundInterscanAnalysisVM.currentStepComplete) {
      return theme.palette.success.main;
    }

    return theme.palette.inProgress.main;
  };

  return (
    <Card data-cy="CompoundInterscanProcessingCard" sx={{
      mx: "3px",
      my: "6px",
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.secondary.dark,
      display: "flex"
    }}>
      <Box data-cy="CompoundInterscanProcessingCardStatusStripe" sx={{ width: 4, backgroundColor: getStripeColor()}}></Box>
      <Grid container spacing={0} alignItems="flex-end">
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{
              textAlign: "right",
              marginRight: "2px",
              fontSize: 12,
              color: theme.palette.primary.light
          }}>
            Study:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{fontSize: 12}}>
            {props.compoundInterscanAnalysisVM.study.studyName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" sx={{
              textAlign: "right",
              marginRight: "2px",
              fontSize: 12,
              color: theme.palette.primary.light
          }}>
            Created:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{fontSize: 12}}>
            {getFormattedDateFromString(props.compoundInterscanAnalysisVM.compoundInterscanAnalysis.initiatedDate, dateFormatWithTime)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.compoundInterscanAnalysisVM.sourceInterscanAnalyses.map((interscanAnalysisVM) => (
            <Box 
            key = {interscanAnalysisVM.interscanAnalysis.id!}
            sx={{ 
              p: 0, 
              my: 0,
              mx: 0, 
              background: "inherit"
            }}>
              <InterscanProcessingCard interscanAnalysisVM={interscanAnalysisVM} />
            </Box>
            ))}
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle2" sx={{
            fontSize: 8,
            color: theme.palette.secondary.dark,
            padding: "2px"
          }}>
            {props.compoundInterscanAnalysisVM.compoundInterscanAnalysis.id}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ScanProcessingStatusIndicator scanAnalysisVM={props.compoundInterscanAnalysisVM} />
        </Grid>
      </Grid>
    </Card>
  );
};
