import { createSelector } from "@reduxjs/toolkit";
import { CompoundInterscanAnalysisViewModelPagedResult, CompoundInterscanAnalysisViewModel } from "../api/aiq-api";
import { enhancedApi } from "../api/enhancedApi";
import { UseQueryResult } from "../api/useQueryResult";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { AllCompoundInterscanApplicationsSelector } from "./applicationSelectors"
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";

/**
 * This selector composes a set of Client-Side Scan Analysis View Models that have corresponding Steps
 * from the data in the state based on the Application/Study currently selected for Scan Processing
 */
export const SelectedStudyCompoundInterscanAnalysisVMsSelector = createSelector<any, Array<ClientSideCompoundInterscanAnalysisViewModel>>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdCompoundInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.compoundInterscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.compoundInterscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.compoundInterscanAnalysisPatientFilter
  })(state)),
  (state: ApplicationState) => AllCompoundInterscanApplicationsSelector(state),
  (
    interscanAnalysisViewModelsResult: UseQueryResult<CompoundInterscanAnalysisViewModelPagedResult>
  ): Array<ClientSideCompoundInterscanAnalysisViewModel> => {
    return ClientSideCompoundInterscanAnalysisVMSelectorMethods.GetClientSideCompoundInterscanAnalysisVMs(interscanAnalysisViewModelsResult);
  }
);

/**
 * A selector returning a boolean indicating whether Scan Analyses for the currently selected Study are loading
 */
 export const SelectedStudyCompoundInterscanAnalysesLoadingSelector = createSelector<any, boolean>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdCompoundInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.compoundInterscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.compoundInterscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.compoundInterscanAnalysisPatientFilter
  })(state as any)),
  (scanAnalyses: UseQueryResult<CompoundInterscanAnalysisViewModelPagedResult>) => scanAnalyses?.isLoading ?? false
);

/**
 * A selector returning the total count of the current set of Compound Interscan Analysis view models
 */
 export const SelectedStudyCompoundInterscanAnalysesTotalCountSelector = createSelector<any, number>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdCompoundInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.compoundInterscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.compoundInterscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.compoundInterscanAnalysisPatientFilter
  })(state as any)),
  (scanAnalyses: UseQueryResult<CompoundInterscanAnalysisViewModelPagedResult>) => scanAnalyses?.data?.totalCount ?? 0
);

/**
 * This selector returns a Client Side Compound Interscan Analysis View Model corresponding to the currently selected Scan Analysis
 */
export const SelectedCompoundInterscanAnalysisVMSelector = createSelector<any, ClientSideCompoundInterscanAnalysisViewModel | undefined>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiCompoundInterscanAnalysisViewModelsById.select({ id: state.compoundInterscanAnalysis.edit.viewedCompoundInterscanAnalysisId!})(state as any)),
  (
    selectedScanAnalysisVMResult: UseQueryResult<CompoundInterscanAnalysisViewModel>,
  ): ClientSideCompoundInterscanAnalysisViewModel | undefined => 
  ClientSideCompoundInterscanAnalysisVMSelectorMethods.GetCompoundInterscanAnalysisVMByCompoundInterscanAnalysis(selectedScanAnalysisVMResult)
);

// These methods constitute the business logic used by the selectors. They are exported so they can be unit tested.
export const ClientSideCompoundInterscanAnalysisVMSelectorMethods = {
  GetCompoundInterscanAnalysisVMByCompoundInterscanAnalysis(
    selectedInterscanAnalysisVMResult: UseQueryResult<CompoundInterscanAnalysisViewModel>,
  ): ClientSideCompoundInterscanAnalysisViewModel | undefined {
    if(selectedInterscanAnalysisVMResult.data) {
        try {
            var newInterscanAnalysisVM =  new ClientSideCompoundInterscanAnalysisViewModel(selectedInterscanAnalysisVMResult.data!);
            return newInterscanAnalysisVM;
        } catch (error) {
          return undefined;            
        }
    }
    return undefined;
  },

  GetClientSideCompoundInterscanAnalysisVMs(
    interscanAnalysisViewModelsResult: UseQueryResult<CompoundInterscanAnalysisViewModelPagedResult>
  ): Array<ClientSideCompoundInterscanAnalysisViewModel> {

    if(!interscanAnalysisViewModelsResult.data?.result) return [];
    var result = new Array<ClientSideCompoundInterscanAnalysisViewModel>();
    interscanAnalysisViewModelsResult.data.result.forEach(backendScanAnalysisVM => {
        try {
            let newScanAnalysisVM = new ClientSideCompoundInterscanAnalysisViewModel(backendScanAnalysisVM!);
            result.push(newScanAnalysisVM);
        } catch (error) {
            console.warn(error);
        }
    });

   return result;
  },
};
