import { Typography, Container, useTheme, Box } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";

import React from "react";
import { InterscanProcessingCard } from "./InterscanProcessingCard";
import { Help } from "@mui/icons-material";
import { AiqTooltip } from "../common/AiqToolTip";
import { InterscanApplicationTaskTemplateApplicationStepTemplate } from "../../app/api/aiq-api";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";

interface IInterscanProcessingLaneProps {
  interscanAnalysisVMs: Array<ClientSideInterscanAnalysisViewModel>;
  stepTemplate: InterscanApplicationTaskTemplateApplicationStepTemplate;

  /**
   * The View Model for the currently dragged Single Scan Processing card (if any)
   */
  draggedScanAnalysis: ClientSideInterscanAnalysisViewModel | undefined;
  cardSelected: (card: ClientSideInterscanAnalysisViewModel) => void;
}

export const InterscanProcessingLane: React.FunctionComponent<IInterscanProcessingLaneProps> = (props) => {
  const theme = useTheme();
  const canDropDraggedCard = (): boolean => {
    return (
      props.draggedScanAnalysis !== undefined &&
      props.draggedScanAnalysis!.canTransitionToStep(props.stepTemplate.systemId!)
    );
  };

  return (
    <Droppable
      droppableId={props.stepTemplate.systemId!}
      /**
       * Only enabled dropping for this lane if there is dragged step whose
       * automatedSuccessorSteps include on corresponding to this lane's
       * component's ID
       */
      isDropDisabled={!canDropDraggedCard()}
    >
      {(provided, snapshot) => (
        <Container
          data-cy="InterscanProcessingLane"
          ref={provided.innerRef}
          disableGutters={true}
          sx={{
            backgroundColor: snapshot.isDraggingOver
              ? theme.palette.secondary.main
              : theme.palette.secondary.light,
            borderRightStyle: "solid" as "solid",
            borderWidth: "thin",
            borderColor: theme.palette.secondary.dark,
            width: "100%",
          }}
        >
          <>
            <Box
              sx={{
                borderBottomColor: theme.palette.secondary.dark,
                borderBottomWidth: "thin",
                borderBottomStyle: "solid" as "solid",
                padding: "3px",
                paddingLeft: "8px",
                color: theme.palette.primary.main,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "36px",
              }}
            >
              <Typography variant="subtitle2" sx={{ fontSize: "0.96em", lineHeight: 1 }}>
                {props.stepTemplate.displayName}
                <AiqTooltip title={props.stepTemplate.description!}>
                  <Help fontSize="small" color="disabled" sx={{ fontSize: "1.2em", marginLeft: "4px" }} />
                </AiqTooltip>
              </Typography>
            </Box>
            {props.interscanAnalysisVMs
              .sort((a: ClientSideInterscanAnalysisViewModel, b: ClientSideInterscanAnalysisViewModel) => {
                return b.dateCreated.getTime() - a.dateCreated.getTime();
              })
              .map((scanAnalysisVM, index) => (
                <Draggable
                  key={scanAnalysisVM.interscanAnalysis.id!}
                  draggableId={scanAnalysisVM.interscanAnalysis.id!}
                  isDragDisabled={scanAnalysisVM.currentStepInProgress}
                  index={index}
                >
                  {(provided2, snapshot) => (
                    <div
                      data-cy="InterscanProcessingCardDraggable"
                      ref={provided2.innerRef}
                      {...provided2.draggableProps}
                      {...provided2.dragHandleProps}
                      onClick={(event) => {
                        props.cardSelected(scanAnalysisVM);
                      }}
                    >
                      <InterscanProcessingCard interscanAnalysisVM={scanAnalysisVM} />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </>
        </Container>
      )}
    </Droppable>
  );
};
