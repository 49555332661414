import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Container,
  Backdrop,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Box,
  Button,
  useTheme,
  Pagination,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { StudySelectMenu } from "./StudySelectMenu";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { Cached } from "@mui/icons-material";
import { SingleScanProcessingLanes } from "./SingleScanProcessingLanes";
import { AiqTooltip } from "../common/AiqToolTip";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import { SelectedStudy } from "../../app/selectors/studySelectors";
import {
  SelectedStudySingleScanAnalysisVMsSelector,
  SelectedStudyScanAnalysesLoadingSelector,
  SelectedStudyScanAnalysesTotalCountSelector,
} from "../../app/selectors/singleScanAnalysisSelectors";
import { ScanPackageProcessingPopup } from "./ScanPackageProcessingPopup";
import { useGetApiSingleScanApplicationsQuery, useGetApiStudiesQuery, Study, useDeleteApiScanAnalysesByIdMutation } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { SingleScanApplicationsByStudyScanAnalyses } from "../../app/selectors/applicationSelectors";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { setSelectedStudy, setSingleScanAnalysisPatientFilter, setSingleScanAnalysisPageNumber } from "../../app/slices/scanAnalysisSlice";
import { FilterMenu } from "./FilterMenu";
import { useRef } from "react";

export const SingleScanProcessingDashboard: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [params, setParams] = useSearchParams();
  const dispatch = useDispatch();
  const selectedStudy = useSelector((state: ApplicationState) => SelectedStudy(state));
  const [showManifestModal, setShowManifestModal] = React.useState<boolean>(false);
  const [showStudySelectModal, setShowStudySelectModal] = React.useState<boolean>(false);
  const singleScanAnalysisVMs = useSelector((state: ApplicationState) => SelectedStudySingleScanAnalysisVMsSelector(state));
  const singleScanAnalysisVMsLoading = useSelector((state: ApplicationState) => SelectedStudyScanAnalysesLoadingSelector(state));
  const [loadSingleScanAnalysisVMs] = enhancedApi.endpoints.getApiStudiesByIdScanAnalysisViewModels.useLazyQuery();
  const {data: studies, isLoading: studiesLoading} =  useGetApiStudiesQuery();
  const { data: applications, isLoading: applicationsLoading } = useGetApiSingleScanApplicationsQuery();
  const filteredApplications = useSelector((state: ApplicationState) => SingleScanApplicationsByStudyScanAnalyses(state));
  const [modalSelectedStudy, setModalSelectedStudy] = React.useState<Study | undefined> (undefined);

  // Pagination and Filtering State
  const singleScanPageNumber: number = useSelector((state: ApplicationState) => state.scanAnalyses.singleScanAnalysisPageNumber);
  const singleScanPageSize: number = useSelector((state: ApplicationState) => state.scanAnalyses.singleScanAnalysisPageSize);
  const singleScanPatientFilter: string | undefined = useSelector((state: ApplicationState) => state.scanAnalyses.singleScanAnalysisPatientFilter);
  const singleScanTotalCount = useSelector(SelectedStudyScanAnalysesTotalCountSelector)

  const updateUrlWithStudyId = (studyId?: string | undefined) => {
    if (studyId) {
      navigate({
        pathname: NavigationConstants.singleScanProcessingPath,
        search: `?${NavigationConstants.scanProcessingStudyIdQueryStringKey}=${studyId!}`,
      });
    }
  };
  const [deleteScanAnalysis, deleteResult] = useDeleteApiScanAnalysesByIdMutation({fixedCacheKey: 'shared-scan-analysis-delete'});

  const autoRefreshTimer = useRef<NodeJS.Timer | undefined>(undefined);
  const refreshTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const forceRefresh = () => {
    if (selectedStudy) {
      loadSingleScanAnalysisVMs({
        id: selectedStudy.id!,
        pageNumber: singleScanPageNumber,
        pageSize: singleScanPageSize,
        patientIdentifierSearchPattern: singleScanPatientFilter
      });
    }
  };

  const createAutoRefreshTimer = () => {
    console.log("Creating auto-refresh timer for single scan processing dashboard");
    autoRefreshTimer.current = setInterval(() => {
      console.log("Auto-refreshing single scan processing dashboard");
      forceRefresh();
    }, AiqConfigurations.scanStatusRefreshIntervalSecs * 1000);
    return () => clearInterval(autoRefreshTimer.current);
  };

  const destroyAutoRefreshTimer = () => {
    if (autoRefreshTimer.current) {
      console.log("Destroying auto-refresh timer for single scan processing dashboard");
      clearInterval(autoRefreshTimer.current);
      autoRefreshTimer.current = undefined;
    }
  };

  const resetAutoRefreshTimer = (refreshImmediately: boolean = false) => {
    destroyAutoRefreshTimer();
    if (refreshImmediately) {
      forceRefresh();
    }
    return createAutoRefreshTimer();
  };

  const createRefreshTimeout = () => {
    refreshTimeout.current = setTimeout(() => {
      forceRefresh();
    }, 1000);
    return () => clearTimeout(refreshTimeout.current);
  };

  const destroyRefreshTimeout = () => {
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
      refreshTimeout.current = undefined;
    }
  };

  const resetRefreshTimeout = () => {
    destroyRefreshTimeout();
    return createRefreshTimeout();
  };

  React.useEffect(() => {
    // On initial load, check the query string for a study id. If there is one, set the selected study to that ID
    if (params.has("studyId")) {
      const studyIdFromUrl = params.get("studyId") as string;
      dispatch(setSelectedStudy(studyIdFromUrl));
    }

    // createAutoRefreshTimer();

    return () => {
      destroyRefreshTimeout();
      destroyAutoRefreshTimer();
    };
  }, []);

  React.useEffect(() => {
    resetAutoRefreshTimer(true);
  }, [singleScanPageNumber, singleScanPatientFilter])

  React.useEffect(() => {
    // TODO: Factor out into seperate component
    // When the studies have loaded, if there is no selcted study
    // we need to select one.
    if(!selectedStudy && studies && studies.length > 0) {
      // If there is only one study, select that one
      if(studies.length == 1) {
        dispatch(setSelectedStudy(studies[0].id!));
        updateUrlWithStudyId(studies[0].id!);
      } else {
        setModalSelectedStudy([...studies].sort((a: Study, b: Study) => {
          if (
            (a.studyName as string).toLocaleLowerCase() <
            (b.studyName as string).toLocaleLowerCase()
          ) {
            return -1;
          }
          if (
            (a.studyName as string).toLocaleLowerCase() >
            (b.studyName as string).toLocaleLowerCase()
          ) {
            return 1;
          }
          return 0;
        })[0]);
        // if there are multiple studies, show the study select menu
        setShowStudySelectModal(true);
      }
    }
  }, [studies]);

  React.useEffect(() => {
    // If the selected study has changed, set the URL query string to reflect it and load the
    // scan analysis view models for the new study
    if (selectedStudy) {
      updateUrlWithStudyId(selectedStudy.id!);
      resetAutoRefreshTimer(true);
    }
  }, [selectedStudy, singleScanPageNumber, singleScanPatientFilter]);

  React.useEffect(() => {
    // If a scan analysis has been deleted, reload the scan analysis VMs
    if (selectedStudy && deleteResult.isSuccess) {
      console.log("Scan analysis deleted, reloading scan analysis VMs");
      deleteResult.reset();
      // Add a delay to make sure the back end has totally processed the delete before requerying
      return resetRefreshTimeout();
    }
  }, [deleteResult]);

  const handleStudySelectionChange = (selectedStudyId: string | undefined) => {
    if (selectedStudyId !== selectedStudy?.id) {
      dispatch(setSelectedStudy(selectedStudyId));
      updateUrlWithStudyId(selectedStudyId);
    }
    setShowStudySelectModal(false);
  };

  const getNumberOfPages = (): number => {
   return Math.ceil(singleScanTotalCount/singleScanPageSize)
  }

  // TODO: Factor out into seperate component
  const renderStudySelectModal = () => {
  return (
    <Modal
      sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          maxHeight: "100%"
      }}
      open={showStudySelectModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box data-cy="StudySelectModal" sx={{
          outline: "none",
          maxHeight: "100%"
      }}>
        {studies && (
          <Paper sx={{width: 600, padding: 10}}>
            <Typography variant="h5">Select a Study</Typography>
            <StudySelectMenu
              studies={[...studies]}
              selectedStudy={modalSelectedStudy}
              onSelectionChanged={(studyId: string | undefined) => handleStudySelectionChange(studyId)}
            />
            <Box sx={{textAlign: "right", p: 2}}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleStudySelectionChange(modalSelectedStudy?.id)}
              >Select</Button>
            </Box>
          </Paper>
        )}
      </Box>
    </Modal>
  );
  };

  const renderScanPackageManifestModal = () => {
    return (
      <Modal
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
            maxHeight: "100%"
        }}
        open={showManifestModal}
        onClose={() => setShowManifestModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box data-cy="ScanPackageProcessingPopup" sx={{
            outline: "none",
            maxHeight: "100%"
        }}>
          <ScanPackageProcessingPopup
            onCancel={() => setShowManifestModal(false)}
            selectedStudyId={selectedStudy!.id!}
          />
        </Box>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {showManifestModal && renderScanPackageManifestModal()}
      {showStudySelectModal && renderStudySelectModal()}
      <Container
        data-cy="SingleScanDashboard"
        maxWidth={false}
        disableGutters={true}
        sx={{height: "100%"}}
      >
        <Backdrop
          sx={{zIndex: theme.zIndex.drawer + 1, color: "#fff"}}
          open={
            (applications?.length == 0 && applicationsLoading) ||
            selectedStudy == undefined ||
            (singleScanAnalysisVMs.length == 0 && singleScanAnalysisVMsLoading)
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <AiqTooltip title="Ingest New Scan Analysis Manifest Package">
          <IconButton
            data-cy="AddScanAnalysisButton"
            sx={{position: "absolute",
            bottom: 20,
            right: 40,}}
            color="primary"
            aria-label="Add"
            size="medium"
            onClick={() => setShowManifestModal(true)}
          >
            <AddCircleIcon fontSize="large" style={{ fontSize: "50px", fontWeight: "lighter" }} />
          </IconButton>
        </AiqTooltip>

        <Grid container alignItems="flex-end" justifyContent="space-between" spacing={0}>
          <Grid item xs={12} md={3}>
            <Typography variant="h4" sx={{
                display: "flex",
                flexFlow: "row wrap",
                width: "100%",
                marginBottom: "6px"
            }}>
              <AiqTooltip title="Refresh Processing Statuses">
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="Refresh"
                  onClick={(event) => {
                    forceRefresh();
                    event.stopPropagation();
                  }}
                >
                  <Cached />
                </IconButton>
              </AiqTooltip>
              Single Scan Processing
            </Typography>
          </Grid>
          {selectedStudy && studies && studies && (
            <React.Fragment>
              <Grid item xs={12} md={3}>
                <StudySelectMenu
                  studies={[...studies]}
                  selectedStudy={selectedStudy}
                  onSelectionChanged={(studyId: string | undefined) => handleStudySelectionChange(studyId)}
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} md={3}>
            <FilterMenu
              filterValue={singleScanPatientFilter}
              onFilterChange={(updatedValue: string | undefined) => dispatch(setSingleScanAnalysisPatientFilter(updatedValue))}
            />
          </Grid>
        </Grid>
        {filteredApplications &&
          filteredApplications.length > 0 &&
          studies &&
          filteredApplications.map((application) => (
            <React.Fragment key={application.systemId}>
              <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between"}}>
                <Typography variant="subtitle2" sx={{
                    display: "flex",
                    flexFlow: "row wrap",
                    width: "auto",
                    marginBottom: "6px"
                }}>
                  {application.displayName} - {application.description}
                </Typography>
              </Box>
              <SingleScanProcessingLanes application={application} onStepTransition={() => forceRefresh()} />
              {getNumberOfPages() > 1 && (
                <Box sx={{display: "flex"}}>
                  <Pagination
                    data-cy="Pagination"
                    count={getNumberOfPages()}
                    page={singleScanPageNumber + 1}
                    onChange={(event: React.ChangeEvent<unknown>, value: number) => dispatch(setSingleScanAnalysisPageNumber(value - 1))  }
                    sx={{marginLeft: 'auto', marginRight: 'auto'}} />
                </Box>
              )}
            </React.Fragment>
          ))}
      </Container>
    </React.Fragment>
  );
};
