import * as React from "react";
import { KeyboardArrowUp, KeyboardArrowDown, Replay, Subject } from "@mui/icons-material";
import { AiqTooltip } from "../common/AiqToolTip";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { ScanProcessingTaskStatusViewModel } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { Toast } from "../common/Toast";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";
import { ClientSideBaseProcessingTaskViewModel } from "../../redux/services/view-models/ClientSideBaseAnalysisTaskViewModel";
import { InterscanApplicationTaskTemplate, SingleScanApplicationTaskTemplate } from "../../app/api/aiq-api";
import { getFormattedDateFromString, dateFormatWithTime } from "../../app/utilities/dateUtilities";
import copy from "copy-to-clipboard";
import { ScanProcessingTaskStatusKinds } from "../../constants/ScanProcessingTaskStatusKinds";
import WarningIcon from "@mui/icons-material/Warning";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import "../../style/Theme";

export interface IScanTaskListItemProps {
  task: ClientSideBaseProcessingTaskViewModel<InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate>;
  showNittyGrittyDetails: boolean;
}

function StatusHistoryCell(props: any) {
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      {props.children}
    </div>
  );
}

function StatusHistoryTextCell(props: any) {
  return (
    <StatusHistoryCell>
      <Typography
        variant="body2"
        noWrap>
        {props.text}
      </Typography>
    </StatusHistoryCell>
  );
}

function StatusHistoryIconAndTextCell(props: any) {
  return (
    <StatusHistoryCell>
      <AiqTooltip title="Click to copy status text to the clipboard">
        <IconButton
          sx={{ color: props.color }}
          onClick={props.onClick}
        >
          {props.icon}
        </IconButton>
      </AiqTooltip>
      <Typography
        variant="body2"
        noWrap
        style={{marginLeft: "8px"}}>
        {props.text}
      </Typography>
    </StatusHistoryCell>
  );
}

function renderStatusHistoryCell(status: ScanProcessingTaskStatusViewModel) {

  const theme = useTheme();

  const kind: string = status.scanProcessingTaskStatus!.kind ?? "";

  const handleStatusHistoryIconClicked = (kind: string, text: string): void => {
    copy(text);
    toast(`${kind} status text copied to clipboard`);
  }

  if (kind === ScanProcessingTaskStatusKinds.update) {
    const percentComplete = status.scanProcessingTaskStatus!.percentComplete ?? 0;
    const text = percentComplete >= 100 ? "Complete" : `${percentComplete}%`;

    return (
      <StatusHistoryTextCell text={text} />
    );
  }

  if (kind === ScanProcessingTaskStatusKinds.warning) {
    const text = `${status.scanProcessingTaskStatus!.result ?? ""}`;
    const icon = (<WarningAmberIcon />);
    const color = theme.palette.warning.main;
    const onClick = () => handleStatusHistoryIconClicked('Warning', text);

    return (
      <StatusHistoryIconAndTextCell
        text={text}
        icon={icon}
        color={color}
        onClick={onClick}
      />
    );
  }

  if (kind === ScanProcessingTaskStatusKinds.alert) {
    const text = `${status.scanProcessingTaskStatus!.error ?? ""}`;
    const icon = (<WarningIcon />);
    const color = theme.palette.alert.main;
    const onClick = () => handleStatusHistoryIconClicked('Alert', text);

    return (
      <StatusHistoryIconAndTextCell
        text={text}
        icon={icon}
        color={color}
        onClick={onClick}
      />
    );
  }

  if (kind === ScanProcessingTaskStatusKinds.error) {
    const text = `${status.scanProcessingTaskStatus!.error ?? ""}`;
    const icon = (<ErrorIcon />);
    const color = theme.palette.error.main;
    const onClick = () => handleStatusHistoryIconClicked('Error', text);

    return (
      <StatusHistoryIconAndTextCell
        text={text}
        icon={icon}
        color={color}
        onClick={onClick}
      />
    );
  }

  return null;
}


/**
 * A simple functional component that displays an item in a list of Scan Processing Tasks
 */
export const ScanTaskListItem: React.FunctionComponent<IScanTaskListItemProps> = (props) => {
  const [rowExpanded, setRowExpanded] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <TableRow data-cy="TaskListItem" sx={{ "& > *": { borderBottom: "unset" }}}>
        <TableCell>
          <IconButton
            data-cy="TaskListItemExpandButton"
            aria-label="expand row"
            size="small"
            onClick={() => setRowExpanded(!rowExpanded)}
          >
            {rowExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {props.task.scanProcessingTask!.id!}
        </TableCell>
        <TableCell align="left">
          {props.task.taskTemplate.description}
        </TableCell>
        <TableCell align="left">
          {props.task.statusDisplayString}
        </TableCell>
        <TableCell align="left">
          {getFormattedDateFromString(props.task?.mostRecentStatus?.scanProcessingTaskStatus?.createdDate, dateFormatWithTime)}
        </TableCell>

        <TableCell align="left">
          <RetryConfirmation task={props.task}/>
        </TableCell>
        <TableCell align="left">
          {props.showNittyGrittyDetails && (
            <TaskDtoModal task={props.task} />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
            <Box margin={1} data-cy="ScanTaskStatusHistory">
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Status ID</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Date/Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.task.orderedScanProcessingTaskStatuses?.map((status: ScanProcessingTaskStatusViewModel) => (
                      <TableRow key={status.scanProcessingTaskStatus!.id}>
                        <TableCell component="th" scope="row">
                          {status.scanProcessingTaskStatus!.id!}
                        </TableCell>
                        <TableCell align="left">
                          {renderStatusHistoryCell(status)}
                        </TableCell>
                        <TableCell align="left">
                          {getFormattedDateFromString(status.scanProcessingTaskStatus!.createdDate, dateFormatWithTime)}
                        </TableCell>
                      </TableRow>
                    ))
                    .reverse()}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const RetryConfirmation: React.FunctionComponent<{ task: ClientSideBaseProcessingTaskViewModel<InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate> }> = (props) => {
  const [showRetry, setShowRetry] = React.useState<boolean>(false);
  const [doRetry, retryRequestResult] = enhancedApi.endpoints.postApiScanProcessingTasksByIdRequestsStart.useMutation();
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if(retryRequestResult.isSuccess) {
      setSnackbarText(`Successfully retried ${props.task.parentStep.scanAnalysisStep?.id} - ${props.task.parentStep.stepTemplate.displayName} task ${props.task.scanProcessingTask!.id} ${props.task.taskTemplate.systemId} for Scan Analysis
      ${props.task.parentStep.scanAnalysisStep!.analysisId}`);

    }
    else if (retryRequestResult.isError) {
      const errorMessage = getErrorMessageFromRtkMutationResult(retryRequestResult);
      setSnackbarText(`Failed to retry ${props.task.parentStep.scanAnalysisStep?.id} - ${props.task.parentStep.stepTemplate.displayName} task ${props.task.scanProcessingTask!.id} ${props.task.taskTemplate.systemId} for Scan Analysis
      ${props.task.parentStep.scanAnalysisStep!.analysisId} - ${errorMessage}`);
    }
  }, [retryRequestResult])

  return (
    <React.Fragment>
      <Backdrop style={{ zIndex: 10, color: "#fff" }} open={retryRequestResult.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toast
        open={snackbarText !== undefined}
        onClose={() => setSnackbarText(undefined)}
        text={snackbarText ?? ""}
      />
      <AiqTooltip title="Retry Task">
        <IconButton
          data-cy="RetryTaskButton"
          size="small"
          color="primary"
          aria-label="Retry Task"
          onClick={(event) => {
            setShowRetry(true);
            event.stopPropagation();
          }}
        >
          <Replay color={props.task.isInProgress ? "secondary" : "primary"} />
        </IconButton>
      </AiqTooltip>
      <ConfirmationDialog
        data-cy="RetryTaskConfirmationDialog"
        title="Retry Scan Processing Task"
        confirmButtonLabel="Retry"
        onCancel={() => setShowRetry(false)}
        onConfirm={async () => {
          doRetry({
            id: props.task.scanProcessingTask!.id!,
            scanProcessingTaskStartRequest: {
              scanProcessingTaskId: props.task.scanProcessingTask!.id,
            },
          });
          setShowRetry(false);
          setSnackbarText(`Retrying ${props.task.parentStep.scanAnalysisStep?.id} - ${props.task.parentStep.stepTemplate.displayName} task ${props.task.scanProcessingTask!.id} ${props.task.taskTemplate.systemId} for Scan Analysis
          ${props.task.parentStep.scanAnalysisStep!.analysisId}`);
        }}
        open={showRetry}
        text={`Do you want to retry 
            ${props.task.parentStep.stepTemplate.displayName}
            task ${props.task.scanProcessingTask.id} - ${props.task.taskTemplate.systemId} for Scan Analysis
            ${props.task.parentStep.scanAnalysisStep.analysisId}?`}
      ></ConfirmationDialog>
    </React.Fragment>
  );
};

const TaskDtoModal: React.FunctionComponent<{task: ClientSideBaseProcessingTaskViewModel<InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate>}> = (props) => {
  const [showDtoModal, setShowDtoModal] = React.useState<boolean>(false);
  const [getDto, getDtoResult] = enhancedApi.endpoints.getApiScanProcessingTasksByIdDto.useLazyQuery();
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);

  return (
    <React.Fragment>
      <Toast
        open={snackbarText !== undefined}
        onClose={() => setSnackbarText(undefined)}
        text={snackbarText ?? ""}
        autoHideDurationMillisecs={6000}
      />
      <AiqTooltip title="View Task DTO">
        <IconButton
          data-cy="ShowDtoButton"
          size="small"
          color="primary"
          aria-label="Retry Task"
          onClick={(event) => {
            getDto({ id: props.task.scanProcessingTask.id! });
            setShowDtoModal(true);
            event.stopPropagation();
          }}
        >
          <Subject color={props.task.isInProgress ? "secondary" : "primary"} />
        </IconButton>
      </AiqTooltip>
      <Dialog
        open={showDtoModal}
        fullWidth={true}
        maxWidth="xl"
        onClose={() => setShowDtoModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"  sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          DTO For Scan Processing Task
          <Box>
            {getDtoResult.data && (
              <AiqTooltip title={"Copy to Clipboard"}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSnackbarText("JSON copied to clipboard");
                    copy(JSON.stringify(getDtoResult.data, null, 2));
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </AiqTooltip>
            )}
          </Box>
        </DialogTitle>
        <DialogContent data-cy="DtoContents">
          {getDtoResult.data && (
            <Box>
              <Typography component={"pre"}>{JSON.stringify(getDtoResult.data, null, 2)}</Typography>
            </Box>
          )}
          {getDtoResult.isLoading && <CircularProgress color="inherit" />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDtoModal(false);
            }}
            variant="contained"
            color="secondary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
