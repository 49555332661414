import * as React from "react";
import { Typography, FormControl, MenuItem, Select, InputLabel, SelectChangeEvent, FormControlLabel, Checkbox, Box } from "@mui/material";
import { SingleScanApplication } from "../../app/api/aiq-api";

export interface IApplicationSelectMenuProps {
  onSelectionChanged: (applicationId: string | undefined) => void;
  selectedApplicationId: string | null;
  nullSelectLabel?: string;
  applications: Array<SingleScanApplication>;
}

/**
 * A simple functional component that displays a menu for selecting studies
 */
export const ApplicationSelectMenu: React.FunctionComponent<IApplicationSelectMenuProps> = (props) => {
  const [showDeprecated, setShowDeprecated] = React.useState<boolean>(false);

  return (
    <Box sx={{display: "flex", alignItems: "flex-end"}}>
      <FormControl variant="standard"  data-cy="ApplicationSelectMenu" sx={{width: "100%"}}>
        <InputLabel id="application-select-label">Application</InputLabel>
        <Select
          labelId="application-select-label"
          id="application-select"
          value={props.selectedApplicationId}
          onChange={(event: SelectChangeEvent) => {
            var value = event.target.value as string | undefined;
            if (value === "ANY") {
              value = undefined;
            }
            props.onSelectionChanged(value);
          }}
        >
          {props.applications.filter(a => (showDeprecated || !a.deprecated) && !a.disabled)
            .map((application: SingleScanApplication) => (
              <MenuItem 
              data-cy="ApplicationSelectMenuPulldown"
              value={application.systemId!} 
              key={application.systemId!}>
                <Typography variant="subtitle1" sx={{ color: application.deprecated ? 'text.disabled' : 'text.primary'  }}>{application.displayName}</Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControlLabel
          sx={{width: 210, ml: 1}}
          control={
              <Checkbox
                  data-cy="ShowOldApplicationVersionsCheckbox"
                  size="small"
                  checked={showDeprecated}
                  onChange={(event) => setShowDeprecated(event.target.checked)}
                  name="ShowDeprecated"
              />
          }
          label={
              <Typography variant="body2">
                  Show Old Versions
              </Typography>
          }
      />
    </Box>
  );
};
