import { ScanProcessingTaskViewModel, InterscanApplicationTaskTemplate } from "../../../app/api/aiq-api";
import { ClientSideBaseProcessingTaskViewModel } from "./ClientSideBaseAnalysisTaskViewModel";
import { ClientSideInterscanAnalysisStepViewModel } from "./ClientSideInterscanAnalysisStepViewModel";


export class ClientSideInterscanProcessingTaskViewModel extends ClientSideBaseProcessingTaskViewModel<InterscanApplicationTaskTemplate> {
  constructor(backendViewModel: ScanProcessingTaskViewModel, parentScanAnalysisStepViewModel: ClientSideInterscanAnalysisStepViewModel) {
    super(backendViewModel, parentScanAnalysisStepViewModel);
    this.checkRequiredFieldsForNull(backendViewModel);
    var task = parentScanAnalysisStepViewModel.stepTemplate.tasks?.find((t) => t.systemId === backendViewModel.scanProcessingTask!.applicationTaskSystemId)

    if(!task) {
      throw new Error(`Can't construct Client Side Interscan Analysis Task View Model for ${backendViewModel.scanProcessingTask?.id} - ${parentScanAnalysisStepViewModel.stepTemplate?.systemId} - no task template with system ID ${backendViewModel.scanProcessingTask?.applicationTaskSystemId} found in application template  ${parentScanAnalysisStepViewModel.stepTemplate?.systemId} ${parentScanAnalysisStepViewModel.scanAnalysisStep.applicationStepSystemId}`);
    }
    this.taskTemplate = task;
  }

  protected override checkRequiredFieldsForNull(backendViewModel: ScanProcessingTaskViewModel) {
    // There is probably a super fancy way to do this but everything I googled looked ugly and unnecessarily complicated 
    // so I went with dumb but simple, like myself - CSS20220315
    if(!backendViewModel.scanProcessingTask) {
      this.throwNullArgConstructionError('scanProcessingTask', backendViewModel)
     }
     if(!backendViewModel.orderedScanProcessingTaskStatuses) {
      this.throwNullArgConstructionError('orderedScanProcessingTaskStatuses', backendViewModel)
     }
  }

  private throwNullArgConstructionError(nullFieldName: string, backendViewModel: ScanProcessingTaskViewModel) {
    throw new Error(`Can't construct client side Interscan Analysis Task View Model for ${backendViewModel.scanProcessingTask?.id} - required field ${nullFieldName} is null or empty`);
  }
}
