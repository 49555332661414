import * as React from "react";

import { Study, StudyStatus } from "../../app/api/aiq-api";
import { StudyForm, IStudyFormViewModel } from "./StudyForm";
import {
  Theme,
  Container,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { produce } from "immer";

interface IStudyInfoProps {
  // The pieces of state we will use
  saving: boolean;
  loading: boolean;
  study: Study;
  usedStudyNames: Array<string>;
  studyStatuses: Array<StudyStatus>;
  onSave: (formData: IStudyFormViewModel) => void;
}

/**
 * This interface defines the shape of state information strictly local to this component
 * and not part of the redux store's state.
 */
interface IStudyInfoState {
  editEnabled: boolean;
}

export class StudyInfoComponent extends React.PureComponent<
  IStudyInfoProps,
  IStudyInfoState
> {
  constructor(props: IStudyInfoProps) {
    super(props);

    this.state = { editEnabled: false } as IStudyInfoState;

    // Wire up our escape key function so we can subscribe it later
    this.escFunction = this.escFunction.bind(this);
  }

  // #region Component Lifcycle methods

  // The component was just mounted for the first time
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  // The component is about to be unmounted. Unsubscribe our escape key listener on unmount
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  // The component has been rerendered because state changed
  public componentDidUpdate(prevProps: IStudyInfoProps) {
    if (prevProps.saving && !this.props.saving) {
      // Just got done saving. Switch back to read only
      this.setState(
        produce((newState) => {
          newState.editEnabled = false;
        })
      );
    }
  }
  //#endregion

  // #region Event Handlers

  handleCancel() {
    this.setState(
      produce((newState) => {
        newState.editEnabled = false;
      })
    );
  }

  handleEdit() {
    this.setState(
      produce((newState) => {
        newState.editEnabled = true;
      })
    );
  }

  //#endregion

  /**
   * Function to capture Escape key presses (so we can respond by closing/navigating away)
   * @param event keystroke event
   */
  private escFunction(event: any) {
    if (event.keyCode === 27) {
      this.handleCancel();
    }
  }

  /**
   * This is the main render function for this component
   */
  public render() {
    if (this.props.study && !this.props.loading) {
      return (
        <React.Fragment>
          <Paper sx={{width: "100%", marginBottom: "10px"}}>
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={9}>
                {" "}
                <Box sx={{display: "flex",
                  flexDirection: "column" as "column",
                  alignItems: "baseline",
                  p: 2}}>
                  <Typography
                    variant="h5"
                    sx={{ mr: 1}}
                    gutterBottom
                  >
                    Study Info
                  </Typography>
                  <Typography variant="subtitle2">{this.props.study.id}</Typography>
                </Box>
              </Grid>
              {!this.state.editEnabled && (
                <Grid item xs={3} sm={1}>
                  <IconButton
                    size="small"
                    color="primary"
                    aria-label="Edit Study"
                    onClick={() => this.handleEdit()}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Divider />
            <StudyForm
              isSaving={this.props.saving}
              study={this.props.study}
              isNewStudy={false}
              existingStudyNames={this.props.usedStudyNames}
              readOnly={!this.state.editEnabled}
              studyStatuses={this.props.studyStatuses}
              onSave={(data: IStudyFormViewModel) => this.props.onSave(data)}
              onCancel={() => this.handleCancel()}
            />
          </Paper>
        </React.Fragment>
      );
    } else {
      return (
        <Container sx={{padding: "20px", textAlign: "center"}}>
          <CircularProgress />
        </Container>
      );
    }
  }
}

/**
 * This component provides UI to display and edit basic Study info
 */
export const StudyInfo = StudyInfoComponent;
