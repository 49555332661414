import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/system";
import { Provider } from "react-redux";
import { store } from "./redux/store/ConfigureStore";
import { App } from "./App";
import theme from "./style/Theme";
import registerServiceWorker from "./registerServiceWorker";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalInstance } from "./redux/authentication";
import { Login } from "./components/common/Login";
import { AiqConfigurations } from "./redux/aiqConfigurations";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
// In your application's entrypoint
import {enableMapSet} from "immer"

enableMapSet()

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Provider store={store}>
          <MsalProvider instance={msalInstance}>
            <div>
              {AiqConfigurations.authenticationEnabled && (
                <React.Fragment>
                  <AuthenticatedTemplate>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <Login />
                  </UnauthenticatedTemplate>
                </React.Fragment>
              )}
              {!AiqConfigurations.authenticationEnabled && (
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              )}
            </div>
          </MsalProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);

registerServiceWorker();
