import * as React from "react";
import { Grid, Typography, Theme, Paper, Button, Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { SingleScanProcessingCard } from "./SingleScanProcessingCard";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { getFormattedDateFromString } from "../../app/utilities/dateUtilities";

interface IInterscanAnalysisDetailsProps {
  interscanAnalysisVM: ClientSideInterscanAnalysisViewModel | undefined;
  showNittyGrittyDetails: boolean;
}

export const InterscanAnalysisDetails: React.FunctionComponent<IInterscanAnalysisDetailsProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSingleScanAnalysisClick = (singleScanAnalysisId: string) => {
    navigate(`${NavigationConstants.singleScanAnalysisPath}/${singleScanAnalysisId}`);
  };

  return (
    <React.Fragment>
      {props.interscanAnalysisVM && (
        <Paper data-cy="InterscanAnalysisDetails" sx={{ width: "100%", marginBottom: "10px" }}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} sm={1}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column" as "column",
                  alignItems: "baseline",
                  p: 2,
                  color: theme.palette.primary.light,
                }}
              >
                <Typography variant="h6" sx={{ mr: 1 }} gutterBottom>
                  Details
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={11}>
              <Grid sx={{ padding: "10px" }} container spacing={1} alignItems="flex-start">
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Study:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.interscanAnalysisVM.study.studyName}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.interscanAnalysisVM.study.id}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right" as "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Application:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={3}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.interscanAnalysisVM.applicationTemplate.displayName}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.interscanAnalysisVM.applicationTemplate.systemId}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right" as "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Created:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {getFormattedDateFromString(props.interscanAnalysisVM.interscanAnalysis.initiatedDate)}
                  </Typography>
                </Grid>
                <Grid item xs={false} sm={false} md={2}></Grid>
                <Grid item xs={5} sm={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right" as "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    {props.interscanAnalysisVM.isGrouped ? "Scan Analysis Groups" : "Scan Analyses"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={11}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {props.interscanAnalysisVM.sourceScanAnalysisGroups.map((singleScanGroup, groupIndex) =>(
                    <Box 
                    key = {groupIndex}
                    sx={{ 
                      display: "flex",
                      flexDirection: props.interscanAnalysisVM!.isGrouped ? "column" : "row",
                      px: .5,
                      mx: props.interscanAnalysisVM!.isGrouped ? .5 : 0, 
                      background: props.interscanAnalysisVM!.isGrouped ? (theme) => theme.palette.secondary.light : "inherit"
                    }}>
                    {singleScanGroup.map((scanAnalysisVM) => (
                      <Button
                        sx={{ textTransform: "none", textAlign: "left", p: 0 }}
                        onClick={() => handleSingleScanAnalysisClick(scanAnalysisVM.scanAnalysis.id!)}
                      >
                        <div key={scanAnalysisVM.scanAnalysis.id!} style={{ maxWidth: 260 }}>
                          <SingleScanProcessingCard scanAnalysisVM={scanAnalysisVM} />
                        </div>
                      </Button>
                    ))}
                    </Box>
                  )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
};
