import * as React from "react";
import {
  Button,
  Container,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Paper,
  Link,
  CircularProgress,
  Typography,
  Dialog,
  DialogContent,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import { PlatformUserForm } from "./PlatformUserForm";
import { Toast } from "../common/Toast";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { PlatformUser, useGetApiPlatformUsersQuery } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";

/**
 * This is a connected component that displays a list of Platform Users
 */
export const PlatformUserList: React.FunctionComponent = (props) => {
  const [newUserDialogOpen, setNewUserDialogOpen] = React.useState<boolean>(false);
  const [saveError, setSaveError] = React.useState<string | undefined>(undefined);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [doSaveUser, saveUserResult] = enhancedApi.endpoints.postApiPlatformUsers.useMutation();
  const {data: platformUsers, isLoading: platformUsersLoading, refetch: refetchPlatformUsers} = useGetApiPlatformUsersQuery();
  const theme = useTheme();

  React.useEffect(() => {
    if(saveUserResult.isSuccess) {
      setSnackbarText("Save successful!");
      refetchPlatformUsers();
    }
    else if (saveUserResult.isError) {
      const errorMessage = getErrorMessageFromRtkMutationResult(saveUserResult);
      setSnackbarText(`An error occurred while trying save the user - ${errorMessage}`);
      setSaveError(errorMessage);
    }
  }, [saveUserResult]);


  const handleSaveNewUser = (user: PlatformUser) => {
    doSaveUser({platformUser: user});
    setNewUserDialogOpen(false);
  }
  const renderNewUserDialog = () => {
    return (
      <Dialog
        open={newUserDialogOpen}
        onClose={() => setNewUserDialogOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogContent>
          <PlatformUserForm
            isLoading={platformUsersLoading}
            isSaving={false}
            user={{} as PlatformUser}
            error={saveError}
            onSave={(user: PlatformUser) => handleSaveNewUser(user)}
            activeRoles={[]}
            allRoles={[]}
            onCancel={() => setNewUserDialogOpen(false)
            }
          />
        </DialogContent>
      </Dialog>
    );
  }

  const renderUsersTable = () => {
    const sortedUsers = platformUsersLoading
      ? []
      : [...platformUsers!].sort((a, b) => (a.platformUserName ?? "").localeCompare(b.platformUserName ?? ""));

    return (
      <TableContainer component={Paper} sx={{maxHeight: "100%"}}>
        {platformUsersLoading ? (
          <Container sx={{padding: "20px", textAlign: "center" }}>
            <CircularProgress />
          </Container>
        ) : (
          <Table stickyHeader aria-labelledby="tableLabel">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>External ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.map((user: PlatformUser) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={`${NavigationConstants.platformUserPath}/${user.id}`}
                      underline="hover">
                      <div>{user.id}</div>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={`${NavigationConstants.platformUserPath}/${user.id}`}
                      underline="hover">
                      <div>{user.platformUserName}</div>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Link
                      component={RouterLink}
                      color="inherit"
                      to={`${NavigationConstants.platformUserPath}/${user.id}`}
                      underline="hover">
                      <div>{user.externalIdentityProviderId}</div>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  }
  return (
    <React.Fragment>
      <div id="tableLabel" sx={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-between",
          width: "100%"
        }}>
        <Typography variant="h4">Platform Users</Typography>
        <Button
          variant="contained"
          onClick={() => setNewUserDialogOpen(true)}
          color="primary"
          style={{ margin: "10px" }}
        >
          <AddIcon /> User
        </Button>
      </div>
      {saveError ? (
        <Container sx={{
          textAlign: "center" as "center",
          padding: "20px",
          color: theme.palette.error.main,
        }}>
          {saveError}
        </Container>
      ) : (
        renderUsersTable()
      )}
      {renderNewUserDialog()}
      <Toast
        open={snackbarText !== undefined}
        onClose={() => setSnackbarText(undefined)}
        text={snackbarText ?? ""}
      />
    </React.Fragment>
  );
}
