
interface IErrorWithMessage {
    data: { Message: string}
}

function isErrorWithMessage(object: any): object is IErrorWithMessage {
    return 'data' in object;
}

/**
 * A utility to extract the error message from an error in a RTK mutation result
 * @param error The RTK Mutation result error
 * @returns The error as a string or 'unknown' if unable to to get the error
 */
export const getErrorMessageFromRtkMutationResult = (error: unknown): string  => {
  return isErrorWithMessage(error) ? error.data.Message : "unknown";
}