import React from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Typography,
  Container,
  Button,
  Box
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Theme } from "@mui/material/styles";
import loginButton from "../../assets/images/ms-symbollockup_signin_dark_short.svg";
import logoImg from "../../assets/images/aiq_logo_72x72.png";
import { login } from "../../app/slices/authSlice";

interface LoginProps {
  login: () => void
}

/**
 * This method is used to map what's in the redux store to this component's props when we
 * connect this component to the redux store. It includes mapping one of our props to a selector.
 * It gets passed to the "connect" method at the bottom.
 */
const mapDispatchToProps = {
 login: login,
};

/**
 * State information strictly specific to this component and not part of the Redux store
 */
interface LoginComponentState {
  errorDialogOpen: boolean;
}
/**
 * This is a connected container component showing the Login UI
 */
class LoginComponent extends React.PureComponent<LoginProps, LoginComponentState> {
  state: LoginComponentState = {
    errorDialogOpen: false,
  };


  public render() {
    return (
      <React.Fragment>
        <Container component="main" maxWidth="sm">
          <Box sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",            
          }}>
            <Box component="img" 
              src={logoImg} 
              alt={"AIQ Solutions"} 
              sx={{
                m: 2,
                height: 100
              }} />

            <Avatar sx={{
              margin: "2px",
              backgroundColor: 'secondary.main',
            }}>
              <LockOutlinedIcon />
            </Avatar>
              <React.Fragment>
                <Typography variant="h5">
                  Click below to sign in with your AIQ Credentials
                </Typography>
                <Button
                  sx={{m: 5}}
                  onClick={this.props.login}
                >
                  <img src={loginButton} alt={"Login with Microsoft"} />
                </Button>
              </React.Fragment>
          </Box>
        </Container>
      </React.Fragment>
    );
  }


}

/**
 * A connected container component showing the Login UI
 */
export const Login = (
  connect(null, mapDispatchToProps)(LoginComponent as any)
);
