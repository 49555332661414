import { red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    inProgress: Palette["primary"];
    alert: Palette["primary"];
  }
  interface PaletteOptions {
    inProgress: PaletteOptions["primary"];
    alert: PaletteOptions["primary"];
  }
}

// A custom theme for this app
const theme: Theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },

    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
      },
    },
  },
  typography: {
    fontSize: 13,
  },
  palette: {
    primary: {
      main: "#811618",
    },
    secondary: {
      main: "#dedede",
      light: "#efefef",
      dark: "#c4c4c4",
    },
    warning: {
      main: "#f9a825",
    },
    alert: {
      main: "#f57c00",
    },
    error: {
      main: red.A400,
      light: "#E35656",
    },
    success: {
      main: "#3AA93C",
      light: "#34F138",
    },
    inProgress: {
      main: "#5d92cf",
    },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
