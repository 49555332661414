import { createSelector } from "@reduxjs/toolkit";
import { InterscanAnalysisViewModelPagedResult, ScanAnalysisViewModel, ScanAnalysisViewModelPagedResult, SingleScanApplication } from "../api/aiq-api";
import { enhancedApi } from "../api/enhancedApi";
import { UseQueryResult } from "../api/useQueryResult";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { AllSingleScanApplicationsSelector } from "./applicationSelectors"

/**
 * This selector composes a set of Client-Side Single Scan Analysis View Models that have corresponding Steps
 * from the data in the state based on the Application/Study currently selected for Scan Processing
 */
export const SelectedStudySingleScanAnalysisVMsSelector = createSelector<any, Array<ClientSideSingleScanAnalysisViewModel>>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdScanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.singleScanAnalysisPageNumber,
    pageSize: state.scanAnalyses.singleScanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.singleScanAnalysisPatientFilter
  })(state as any)),
  (
    scanAnalysisViewModelsResult: UseQueryResult<ScanAnalysisViewModelPagedResult>,
  ): Array<ClientSideSingleScanAnalysisViewModel> => {
    if(scanAnalysisViewModelsResult.data?.result == undefined)  return [];
    return ClientSideSingleScanAnalysisVMSelectorMethods.GetClientSideScanAnalysisVMs(scanAnalysisViewModelsResult.data.result);
  }
);


/**
 * This selector composes a set of Client-Side Single Scan Analysis View Models that are associated with the
 * given Interscan Analysis
 */
 export const SelectedInterscanAnalysisSingleScanAnalysisVMsSelector = createSelector<any, Array<Array<ClientSideSingleScanAnalysisViewModel>>>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiInterscanAnalysesByIdScanAnalysisViewModels.select({ id: state.interscanAnalysis.edit.viewedInterscanAnalysisId!})(state as any)),
  (
    scanAnalysisViewModelsResult: UseQueryResult<Array<Array<ScanAnalysisViewModel>>>,
  ): Array<Array<ClientSideSingleScanAnalysisViewModel>> => {
    if(scanAnalysisViewModelsResult.data == undefined)  return [[]];
    return scanAnalysisViewModelsResult.data?.map(
      singleScanAnalysisGroup => ClientSideSingleScanAnalysisVMSelectorMethods
      .GetClientSideScanAnalysisVMs(singleScanAnalysisGroup));
  }
);

/**
 * A selector returning a boolean indicating whether Scan Analyses for the currently selected Study are loading
 */
 export const SelectedStudyScanAnalysesLoadingSelector = createSelector<any, boolean>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdScanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.singleScanAnalysisPageNumber,
    pageSize: state.scanAnalyses.singleScanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.singleScanAnalysisPatientFilter
  })(state as any)),
  (scanAnalyses: UseQueryResult<ScanAnalysisViewModelPagedResult>) => scanAnalyses?.isLoading ?? false
);

/**
 * A selector returning the total count of the current set of Scan Analysis view models
 */
 export const SelectedStudyScanAnalysesTotalCountSelector = createSelector<any, number>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdScanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.singleScanAnalysisPageNumber,
    pageSize: state.scanAnalyses.singleScanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.singleScanAnalysisPatientFilter
  })(state as any)),
  (scanAnalyses: UseQueryResult<ScanAnalysisViewModelPagedResult>) => scanAnalyses?.data?.totalCount ?? 0
);

/**
 * This selector returns a Client Side Scan Analysis View Model corresponding to the currently selected Scan Analysis
 */
export const SelectedScanAnalysisVMSelector = createSelector<any, ClientSideSingleScanAnalysisViewModel | undefined>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiScanAnalysisViewModelsById.select({ id: state.scanAnalyses.edit.viewedScanAnalysisId!})(state as any)),
  (
    selectedScanAnalysisVMResult: UseQueryResult<ScanAnalysisViewModel>,
  ): ClientSideSingleScanAnalysisViewModel | undefined => {
    if (!selectedScanAnalysisVMResult.data) return undefined;

    if(selectedScanAnalysisVMResult.data) {
      try {
        return new ClientSideSingleScanAnalysisViewModel(selectedScanAnalysisVMResult.data!)        
      } catch (error) {
        console.warn(error);
      }
    }
    return undefined;
  }
);

/**
 * This selector returns a list of Scan Analysis IDs corresponding to the Interscan Analyses
 * from the data in the state based on the Application/Study currently selected for Scan Processing
 */
 export const SelectedStudyInterscanAnalysisScanAnalysisIdsSelector = createSelector<any, Array<string>>(
  (state: ApplicationState) => (enhancedApi.endpoints.getApiStudiesByIdInterscanAnalysisViewModels.select({
    id: state.scanAnalyses.selectedStudyId!,
    pageNumber: state.scanAnalyses.interscanAnalysisPageNumber,
    pageSize: state.scanAnalyses.interscanAnalysisPageSize,
    patientIdentifierSearchPattern: state.scanAnalyses.interscanAnalysisPatientFilter
  })(state as any)),
  (
    interscanAnalysisViewModelsResult: UseQueryResult<InterscanAnalysisViewModelPagedResult>,
  ): Array<string> => 
  { 
    if(interscanAnalysisViewModelsResult.data?.result == undefined) return []
    let sets = interscanAnalysisViewModelsResult.data.result.flatMap(isa => isa.sourceScanAnalyses);
    let flattenedSets = sets.flatMap(x => x);
    return [... new Set(flattenedSets.map(x => x!.id!))]
  }
);

/**
 * This selector composes a set of Client-Side Single Scan Analysis View Models that have corresponding Steps
 * from the data in the state based on the set of Interscan Analyses currently selected
 */
 export const SingleScanAnalysisVMByInterscanAnalysesSelector = createSelector<any, Array<ClientSideSingleScanAnalysisViewModel>>(
  (state: ApplicationState) => 
    {
      const visibleInterscanAnalysesScanAnalysisIds = SelectedStudyInterscanAnalysisScanAnalysisIdsSelector(state as any);
      var result = enhancedApi.endpoints.getApiStudiesByIdScanAnalysisViewModelsByIds.select({
        id: state.scanAnalyses.selectedStudyId!,
        scanAnalysisIds: visibleInterscanAnalysesScanAnalysisIds.join(", ")
      })(state as any);
      return result;
    },
  (
    scanAnalysisViewModelsResult: UseQueryResult<Array<ScanAnalysisViewModel>>
  ): Array<ClientSideSingleScanAnalysisViewModel> => {
    if(scanAnalysisViewModelsResult.data == undefined) return [];
    return ClientSideSingleScanAnalysisVMSelectorMethods.GetClientSideScanAnalysisVMs(scanAnalysisViewModelsResult.data);
  }
);


// These methods constitute the business logic used by the selectors. They are exported so they can be unit tested.
export const ClientSideSingleScanAnalysisVMSelectorMethods = {
  GetScanAnalysisVMByScanAnalysis(
    selectedScanAnalysisVMResult: UseQueryResult<ScanAnalysisViewModel>,
  ): ClientSideSingleScanAnalysisViewModel | undefined {
    return selectedScanAnalysisVMResult.data
      ? new ClientSideSingleScanAnalysisViewModel(selectedScanAnalysisVMResult.data!)
      : undefined;
  },
  GetClientSideScanAnalysisVMs(
    scanAnalysisViewModelsResult: Array<ScanAnalysisViewModel>
  ): Array<ClientSideSingleScanAnalysisViewModel> {

    if(!scanAnalysisViewModelsResult || scanAnalysisViewModelsResult.length == 0) return [];
    var result = new Array<ClientSideSingleScanAnalysisViewModel>();
    scanAnalysisViewModelsResult.forEach((backendScanAnalysisVM) => {
        result.push(new ClientSideSingleScanAnalysisViewModel(backendScanAnalysisVM));
    });

    return result;
  },
};
