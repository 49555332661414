import * as React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import {
  Grid,
  Checkbox,
  Typography,
  Theme,
  Button,
  FormControlLabel,
  Box,
  useTheme,
} from "@mui/material";
import { PlatformRole, PlatformUser } from "../../app/api/aiq-api";

/**
 * This interface defines the shape of the data this component expects for its props.
 */
interface IUserFormProps {
  user: PlatformUser;
  activeRoles: Array<PlatformRole>;
  allRoles: Array<PlatformRole>;
  onSave: (formData: IPlatformUserFormViewModel) => void;
  onCancel: () => void;
  isLoading: boolean;
  isSaving: boolean;
  error: string | undefined;
}

/**
 * This interface defines the shape of the data that the PlatformUserForm works with
 */
export interface IPlatformUserFormViewModel {
  platformUserName: string | undefined;
  externalIdentityProviderId: string | undefined;
  id: string | undefined;
  activeRoleIds: Array<string>;
}

/**
 * This is a dumb component that displays an editing UI for a Platform User
 */
export const PlatformUserForm: React.FunctionComponent<IUserFormProps> = (props) => {
  const [loadedValues, setLoadedValues] = React.useState<IPlatformUserFormViewModel | undefined>({
    platformUserName: props.user.platformUserName,
    externalIdentityProviderId: props.user.externalIdentityProviderId,
    id: props.user.id,
    activeRoleIds: props.activeRoles.map((r) => r.id),
  } as IPlatformUserFormViewModel);
  const handleSubmit = (data: IPlatformUserFormViewModel) => {
    props.onSave(data);
  }
  const theme = useTheme();

  React.useEffect(() => {
    setLoadedValues({
      platformUserName: props.user.platformUserName,
      externalIdentityProviderId: props.user.externalIdentityProviderId,
      id: props.user.id,
      activeRoleIds: props.activeRoles.map((r) => r.id),
    } as IPlatformUserFormViewModel);
  }, []);


  return (
    <React.Fragment>
      {loadedValues && (
        <Formik<IPlatformUserFormViewModel>
          initialValues={loadedValues}
          enableReinitialize
          validationSchema={Yup.object({
            platformUserName: Yup.string()
              .max(64, "Must be 64 characters or less")
              .required("Required"),
            externalIdentityProviderId: Yup.string().max(
              64,
              "Must be 64 characters or less"
            ),
          })}
          onSubmit={(data, { setSubmitting }) => {
            handleSubmit(data);
            setSubmitting(false);
          }}
        >
          {({ submitForm, dirty, values }) => (
            <Form>
              <Grid container spacing={3} sx={{p: 2}}>
                {/* User Name */}
                <Grid item xs={12} sm={6}>
                  <Field
                    name="platformUserName"
                    label="User Name"
                    fullWidth
                    autoFocus
                    variant="filled"
                    tabIndex="1"
                    component={TextField}
                    disabled={props.isSaving}
                  />
                </Grid>
                {/* External ID */}
                <Grid item xs={12} sm={6}>
                  <Field
                    name="externalIdentityProviderId"
                    label="External Identity Provider User ID"
                    fullWidth
                    tabIndex="2"
                    variant="filled"
                    component={TextField}
                    disabled={props.isSaving}
                  />
                </Grid>
                {/* Roles */}
                <Grid item xs={12}>
                  <FieldArray
                    name="activeRoleIds"
                    render={(arrayHelpers) => (
                      <div>
                        {/* Iterate over the array of available roles and render a checkbox */}
                        {props.allRoles.map((role, index) => (
                          <FormControlLabel
                            key={index}
                            value={role.platformRoleName}
                            control={
                              <Checkbox
                                name="activeRoleIds"
                                value={role.id}
                                checked={
                                  values?.activeRoleIds?.includes(role.id || "UNDEFINED") ?? false
                                }
                                onChange={(e) => {
                                  /**
                                   * On check or un-check, Use the Formik FieldArray arrayHelpers to add or
                                   * remove roles to/from our collection of selected roles
                                   */
                                  if (e.target.checked) arrayHelpers.push(role.id);
                                  else {
                                    const idx = values.activeRoleIds.indexOf(
                                      role.id || "UNDEFINED"
                                    );
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                              />
                            }
                            label={role.platformRoleName}
                            labelPlacement="end"
                          />
                        ))}
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{
                      display: "flex",
                      flexFlow: "row wrap",
                      "& Button": {
                        marginRight: "10px",
                      },
                      }}>
                    {/* Submit button */}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={props.isSaving || !dirty}
                      onClick={submitForm}
                    >
                      {props.isSaving ? "Saving..." : "Save"}
                    </Button>{" "}
                    {/* Cancel button */}
                    <Button
                      variant="contained"
                      onClick={props.onCancel}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      {/* Error display */}
      {props.error && (
        <Typography sx={{
          color: theme.palette.error.main,
          textAlign: "center",
          padding: "5px",}}>{props.error}</Typography>
      )}
    </React.Fragment>
  );
}
