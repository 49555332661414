import { createSelector } from "@reduxjs/toolkit"
import { Study, Timepoint } from "../api/aiq-api";
import { enhancedApi } from "../api/enhancedApi";
import { UseQueryResult } from "../api/useQueryResult";
import { ApplicationState } from "../../redux/store/ConfigureStore";

export const SelectedStudy = createSelector<
  any, // State
  Study | undefined // Return type
>(
  (state: ApplicationState) => enhancedApi.endpoints.getApiStudies.select()(state as any),
  (state: ApplicationState) => state.scanAnalyses.selectedStudyId,
  (
    studiesResult: UseQueryResult<Array<Study>>,
    selectedStudyId: string | undefined,
  ): Study | undefined => {
      if(!selectedStudyId || !studiesResult.data) return undefined;
      return studiesResult.data.find(study => study.id === selectedStudyId);
  }
);


/**
 * This selector provides all the currently used Study names, trimmed a to-lowered
 */
 export const UsedStudyNamesLowerCase = createSelector<any, Array<string>>(
  (state: ApplicationState) => enhancedApi.endpoints.getApiStudies.select()(state),
  (studies: UseQueryResult<Array<Study>>) => {
    return studies.data?.map((study: Study) => (study.studyName as string).toLowerCase().trim()) ?? [];
  }
);

/**
 * This selector provides the study currently selected for viewing/editing
 */
export const ViewedEditStudySelector = createSelector<any, Study | undefined>(
  (state: ApplicationState) => enhancedApi.endpoints.getApiStudies.select()(state),
  (state: ApplicationState) => state.studies.edit.viewedStudyId,
  (studies: UseQueryResult<Array<Study>>, id: string | undefined) => (id ? studies.data?.find((study: Study) => study.id === id) : undefined)
);

/**
 * This selector returns the timepoints for the currently viewed study
 */
export const ViewedStudyTimepointsSelector = createSelector<any, Array<Timepoint>>(
  (state: ApplicationState) => enhancedApi.endpoints.getApiTimepoints.select()(state),
  (state: ApplicationState) => state.studies.edit.viewedStudyId,
  (timepoints: UseQueryResult<Array<Timepoint>>, studyId: string | undefined) =>
    studyId && timepoints.data ? timepoints.data.filter((tp) => tp.studyId === studyId) : []
);


/**
 * A selector returning a boolean indicating whether Studies are currently loading
 */
 export const StudiesLoadingSelector = createSelector<any, boolean>(
  enhancedApi.endpoints.getApiStudies.select(),
  (studies: UseQueryResult<Array<Study>>) => studies?.isLoading ?? false
);