import { ScanProcessingTask, ScanProcessingTaskViewModel, ScanProcessingTaskStatusViewModel, SingleScanApplicationTaskTemplate, InterscanApplicationTaskTemplate } from "../../../app/api/aiq-api";
import { ClientSideBaseAnalysisStepViewModel } from "./ClientSideBaseAnalysisStepViewModel";

/**
 * A base view model for view models used for UI related to Interscan or Scan Analysis Scan Processing tasks
 */
export abstract class ClientSideBaseProcessingTaskViewModel<TTaskTemplateViewmodel extends SingleScanApplicationTaskTemplate | InterscanApplicationTaskTemplate> {
  constructor(backendViewModel: ScanProcessingTaskViewModel, parentScanAnalysisStepViewModel: ClientSideBaseAnalysisStepViewModel<TTaskTemplateViewmodel>) {
    this.checkRequiredFieldsForNull(backendViewModel);

    this.scanProcessingTask = backendViewModel.scanProcessingTask!;
    this.orderedScanProcessingTaskStatuses = backendViewModel.orderedScanProcessingTaskStatuses!;
    this.isInProgress = backendViewModel.isInProgress!;
    this.mostRecentStatus = backendViewModel.mostRecentStatus!;
    this.percentComplete = backendViewModel.percentComplete!;
    this.isComplete = backendViewModel.isComplete!;
    this.isWaiting = backendViewModel.isWaiting!;
    this.hasErrors = backendViewModel.hasErrors!;  
    this.parentStep = parentScanAnalysisStepViewModel;
  }

  scanProcessingTask!: ScanProcessingTask;
  orderedScanProcessingTaskStatuses!: ScanProcessingTaskStatusViewModel[];
  isInProgress!: boolean;
  mostRecentStatus!: ScanProcessingTaskStatusViewModel;
  percentComplete!: number;
  isComplete!: boolean;
  isWaiting!: boolean;
  hasErrors!: boolean;
  taskTemplate!: TTaskTemplateViewmodel;
  parentStep!: ClientSideBaseAnalysisStepViewModel<TTaskTemplateViewmodel>;

  get statusDisplayString(): string {
    if(this.hasErrors) {
      return `Error`
    }
    if(this.isComplete) {
      return 'Complete'
    }
    if(this.isInProgress) {
      return `In Progress - ${this.mostRecentStatus?.scanProcessingTaskStatus?.percentComplete}%`
    }
    if(this.isWaiting) {
      return `Awaiting Processing`
    }
    return `Unknown`
  }

  protected abstract checkRequiredFieldsForNull(backendViewModel: ScanProcessingTaskViewModel): void;
}
