import {  InterscanAnalysisViewModel, CompoundInterscanApplication, CompoundInterscanAnalysis, CompoundInterscanApplicationTaskTemplateApplicationStepTemplate, CompoundInterscanAnalysisViewModel } from "../../../app/api/aiq-api";
import { ClientSideBaseAnalysisViewModel } from "./ClientSideBaseScanAnalysisViewModel";
import { ClientSideInterscanAnalysisViewModel } from "./ClientSideInterscanAnalysisViewModel";
import { ClientSideCompoundInterscanAnalysisStepViewModel } from "./ClientSideCompoundInterscanAnalysisStepViewModel";


/**
 * A client-side view model for displaying UIs for a Compound Interscan Analysis
 */
export class ClientSideCompoundInterscanAnalysisViewModel extends ClientSideBaseAnalysisViewModel {
  constructor(backendViewModel: CompoundInterscanAnalysisViewModel) {
    super(backendViewModel);
    this.checkRequiredFieldsForNull(backendViewModel);
    this.sourceInterscanAnalyses = backendViewModel.sourceInterscanScanAnalysisVMs?.map(x => new ClientSideInterscanAnalysisViewModel(x)) ?? [];
    this.applicationTemplate = backendViewModel.compoundInterscanAnalysisApplication!;
    this.compoundInterscanAnalysis = backendViewModel.compoundInterscanAnalysis!;
    this.orderedScanAnalysisSteps = backendViewModel.orderedScanAnalysisSteps!.map(step => new ClientSideCompoundInterscanAnalysisStepViewModel(step, this));
  }

  sourceInterscanAnalyses!: Array<ClientSideInterscanAnalysisViewModel>;
  orderedScanAnalysisSteps!: Array<ClientSideCompoundInterscanAnalysisStepViewModel>;
  compoundInterscanAnalysis: CompoundInterscanAnalysis;
  applicationTemplate!: CompoundInterscanApplication;

  /**
   * The step that this scan analysis can automatically move on to
   */
  automatedSuccessorComponentId: string | undefined;

  get dateCreated(): Date {
    return new Date(this.compoundInterscanAnalysis.initiatedDate!);
  }

  protected isStepAutomated(stepSystemId: string): boolean {
    var step: CompoundInterscanApplicationTaskTemplateApplicationStepTemplate | undefined = 
      this.applicationTemplate.steps?.find((s: CompoundInterscanApplicationTaskTemplateApplicationStepTemplate) => s.systemId === stepSystemId);
    return step?.automated ?? false;
  }

  protected checkRequiredFieldsForNull(backendViewModel: CompoundInterscanAnalysisViewModel) {
    // There is probably a super fancy way to do this but everything I googled looked ugly and unnecessarily complicated 
    // so I went with dumb but simple, like myself - CSS20220315
    if(!backendViewModel.sourceInterscanScanAnalysisVMs || backendViewModel.sourceInterscanScanAnalysisVMs.length === 0) {
      this.throwNullArgConstructionError('sourceInterscanScanAnalysisVMs', backendViewModel)
     }
     if(!backendViewModel.orderedScanAnalysisSteps || backendViewModel.orderedScanAnalysisSteps.length == 0) {
      this.throwNullArgConstructionError('orderedScanAnalysisSteps', backendViewModel)
     }
     if(!backendViewModel.study) {
      this.throwNullArgConstructionError('study', backendViewModel)
     }
     if(!backendViewModel.compoundInterscanAnalysis) {
      this.throwNullArgConstructionError('compoundInterscanAnalysis', backendViewModel)
     }
  }

  private throwNullArgConstructionError(nullFieldName: string, backendViewModel: InterscanAnalysisViewModel) {
    throw new Error(`Can't construct client side Interscan Analysis View Model for ${backendViewModel.interscanAnalysis?.id} - required field ${nullFieldName} is null or empty`);
  }
}
