import * as React from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box } from "@mui/material";
import { ScanTaskListItem } from "./ScanTaskListItem";
import { ClientSideBaseAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideBaseAnalysisStepViewModel";
import { InterscanApplicationTaskTemplate, SingleScanApplicationTaskTemplate } from "../../app/api/aiq-api";

export interface IScanTaskListProps {
  step: ClientSideBaseAnalysisStepViewModel<InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate>;
  showNittyGrittyDetails: boolean;
}

/**
 * A simple functional component that displays a list of Scan Processing Tasks
 */
export const ScanTaskList: React.FunctionComponent<IScanTaskListProps> = (
  props
) => {
  return (
    <Box sx={{width: "100%"}}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell align="left" width={30} style={{fontWeight: "bold"}}></TableCell>
              <TableCell align="left" width={300} style={{fontWeight: "bold"}}>Task ID</TableCell>
              <TableCell align="left" style={{fontWeight: "bold"}}>Description</TableCell>
              <TableCell align="left" width={140} style={{fontWeight: "bold"}}>Status</TableCell>
              <TableCell align="left" width={300} style={{fontWeight: "bold"}}>Last Updated</TableCell>
              <TableCell align="left" width={30} style={{fontWeight: "bold"}}></TableCell>
              <TableCell align="left" width={30} style={{fontWeight: "bold"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.step.tasks?.map((task) => (
              <ScanTaskListItem 
                key =  {task.scanProcessingTask.id}
                task = {task} 
                showNittyGrittyDetails={props.showNittyGrittyDetails}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
