import { api, ScanAnalysisStep, ScanAnalysisViewModel } from "./aiq-api";

/**
 * Adds tags used to control caching between endpoints
 */
export const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["Study", "ScanAnalysisViewModel", "ScanAnalysisViewModelByStudy"],
  endpoints: {
    getApiStudies: { providesTags: ["Study"] },
    postApiStudies: { invalidatesTags: ["Study"] },
    putApiStudiesById: { invalidatesTags: ["Study"] },
    getApiScanAnalysisViewModelsById: {
      providesTags: (result: ScanAnalysisViewModel | undefined) =>
        result ? [{ type: "ScanAnalysisViewModel", id: result.scanAnalysis!.id! }] : [],
    },
    getApiStudiesByIdScanAnalysisViewModels: {
      providesTags: (results) =>
        results?.result
          ? results.result.map((result) => ({ type: "ScanAnalysisViewModelByStudy", id: result.study!.id! }))
          : [],
    },
    postApiScanAnalysisStepsByIdTransition: {
      invalidatesTags: (result: ScanAnalysisStep | undefined) =>
        result ? [{ type: "ScanAnalysisViewModel", id: result.analysisId! }] : [],
    },
    postApiPlatformUsersByIdRoles: {
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}/roles`,
        method: "POST",
        body: queryArg.body,
        headers: {
          "content-type": "application/json",
        },
      }),
    },
  },
});
