import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import {
  AsyncThunk,
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { msalInstance } from "../../redux/authentication";
import { AiqConfigurations } from "../../redux/aiqConfigurations";

/**
 * This thunk action will try to get a valid access token by checking local storage for a token
 * and making an async call in the background to refresh the token, if necessary
 */
 export const tryRefreshAuthToken: AsyncThunk<string | null, void, {}> = createAsyncThunk(
  "auth/tryAcquireToken",
  async () => {
      const currentAccount: AccountInfo = msalInstance.getAllAccounts()[0];
      if (!currentAccount) {
          console.log(`No account`);
          return null;
      }

      let request = {
          account: currentAccount,
          scopes: [AiqConfigurations.oAuthScope],
      };

      const result: AuthenticationResult = await msalInstance.acquireTokenSilent(request);
      return result.accessToken;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: { token: null, authenticating: false } as AuthState,
  reducers: {
    logout: (state) => {
      state.authenticating = true;
      state.token = null;
      msalInstance.logoutRedirect();
    },
    login: () => {
      let request = {
        scopes: [AiqConfigurations.oAuthScope],
      };
      msalInstance.acquireTokenRedirect(request);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(tryRefreshAuthToken.pending, (state) => {
      state.authenticating = true;
      state.token = null;
    });
    builder.addCase(tryRefreshAuthToken.fulfilled, (state, action) => {
      state.authenticating = false;
      state.token = action.payload;
    });
    builder.addCase(tryRefreshAuthToken.rejected, (state) => {
      state.authenticating = false;
      state.token = null;
      let request = {
        scopes: [AiqConfigurations.oAuthScope],
      };
      msalInstance.acquireTokenRedirect(request);
    });
  },
});

export type AuthState = {
  token: string | null;
  authenticating: boolean;
};

export const { login, logout } = authSlice.actions;

export const authSliceReducer = authSlice.reducer