import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import { tryRefreshAuthToken } from "../slices/authSlice";

const baseQuery = fetchBaseQuery({
    baseUrl: AiqConfigurations.baseUrl,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = (getState() as ApplicationState).auth.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
    async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);
        if (result.error && result.error.status === 401) {
            // Poke the auth layer to try to refresh the token
            await api.dispatch(tryRefreshAuthToken());

            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        }
        return result;
    };
