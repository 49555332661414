import * as React from "react";
import { Grid, Typography, Paper, IconButton, useTheme } from "@mui/material";
import { ScanTaskList } from "./ScanTaskList";
import { AiqTooltip } from "../common/AiqToolTip";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { SkipNext } from "@mui/icons-material";
import { Toast } from "../common/Toast";
import { ScanStepArtifacts } from "./ScanStepArtifacts";
import { enhancedApi } from "../../app/api/enhancedApi";
import { ClientSideBaseAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideBaseAnalysisStepViewModel";
import { CompoundInterscanApplicationTaskTemplate, InterscanApplicationTaskTemplate, SingleScanApplicationTaskTemplate } from "../../app/api/aiq-api";
import { RoiRemoval } from "../roiRemoval/RoiRemoval";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { dateFormatWithTime, getFormattedDateFromString } from "../../app/utilities/dateUtilities";
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";

type IScanAnalysisStepProps = {
  showNittyGrittyDetails: boolean;
  step: ClientSideBaseAnalysisStepViewModel<InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate | CompoundInterscanApplicationTaskTemplate>;
  scanAnalysis: ClientSideSingleScanAnalysisViewModel | ClientSideInterscanAnalysisViewModel | ClientSideCompoundInterscanAnalysisViewModel;
  onForceRefresh: () => void;
};

export const ScanAnalysisStep: React.FunctionComponent<IScanAnalysisStepProps> = (props) => {
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [showMarkCompleteDialog, setShowMarkCompleteDialog] = React.useState<boolean>(false);
  const [markComplete] = enhancedApi.usePostApiScanAnalysisStepsByIdManuallyMarkCompleteMutation();
  const theme = useTheme();

  // noinspection JSUnusedLocalSymbols
  const handleMarkCompleteClicked = (
    step: ClientSideBaseAnalysisStepViewModel<
      InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate | CompoundInterscanApplicationTaskTemplate
    >
  ) => {
    setShowMarkCompleteDialog(true);
  };

  const handleMarkCompleteConfirmed = async () => {
    setShowMarkCompleteDialog(false);
    setSnackbarText(`Marking step ${props.step.scanAnalysisStep.id!}`);
    markComplete({
      id: props.step.scanAnalysisStep.id!,
      scanAnalysisStepManualCompletionRequest: { stepId: props.step.scanAnalysisStep.id! },
    });
    props.onForceRefresh();
  };

  const closeMarkCompleteDialog = () => {
    setShowMarkCompleteDialog(false);
  };

  const getStatusColor = ( step: ClientSideBaseAnalysisStepViewModel<
    InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate
  >): string => {
    let statusColor = theme.palette.secondary.main;
    if (step.hasErrors) {
      statusColor = theme.palette.error.main;
    } else if (step.isComplete) {
      statusColor = theme.palette.success.main;
    }
    return statusColor;
   }

  const renderMarkCompleteConfirmationModal = () => {
    return (
      <ConfirmationDialog
        title="Mark Step Complete"
        confirmButtonLabel="Mark Complete"
        onCancel={() => closeMarkCompleteDialog()}
        onConfirm={() => handleMarkCompleteConfirmed()}
        open={showMarkCompleteDialog}
        text={`Do you want to manually mark the ${props.step?.stepTemplate.displayName} 
        step for Scan Analysis ${props.step.scanAnalysisStep.analysisId} complete? This is outside the normal workflow.`}
      ></ConfirmationDialog>
    );
  };

  const renderStepDetailsContent = () => {
    return (
      <React.Fragment>
        <Grid container sx={{ p: 2 }}>
        { ! (props.step.isWaiting || props.step.isInProgress) && (
          <React.Fragment>
          <Grid item xs={12} sx={{display: "flex",
            flexWrap: "wrap" as "wrap",
            alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "right",
                marginRight: "2px",
                color: theme.palette.primary.light,
              }}
            >
              Artifacts
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <ScanStepArtifacts step={props.step} scanAnalysis={props.scanAnalysis} showNittyGrittyDetails={props.showNittyGrittyDetails} />
          </Grid>
          </React.Fragment>)}
          {props.step.tasks.length > 0 && (
            <React.Fragment>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap" as "wrap",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "right",
                    marginRight: "2px",
                    color: theme.palette.primary.light,
                  }}
                >
                  Tasks
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap" as "wrap",
                  alignItems: "center",
                }}
              >
                <ScanTaskList step={props.step} showNittyGrittyDetails={props.showNittyGrittyDetails} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        {renderMarkCompleteConfirmationModal()}
      </React.Fragment>
    );
  };

  const renderRoiRemovalContent = () => {
    return (
      <React.Fragment>
        <Grid container sx={{ p: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexWrap: "wrap" as "wrap",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "right",
                marginRight: "2px",
                color: theme.palette.primary.light,
              }}
            >
              Artifacts
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <ScanStepArtifacts scanAnalysis={props.scanAnalysis} step={props.step} showNittyGrittyDetails={props.showNittyGrittyDetails} />
          </Grid>
          <Grid item xs={12}>
            <RoiRemoval
              step={props.step}
              scanAnalysis={props.scanAnalysis as ClientSideSingleScanAnalysisViewModel}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  //console.log(`"scan analysis step is complete: ${props.step.isComplete}`);

  return (
    <React.Fragment>
      <Paper
        sx={{ width: "100%", marginBottom: 10 }}
        key={props.step.scanAnalysisStep.id}
        data-cy="ScanAnalysisStep"
      >
        <Grid container>
          <Grid item sx={{ p: 2 }} sm={12} md={10}>
            <Grid container spacing={1} direction="row">
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap" as "wrap",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "right",
                    marginRight: "2px",
                    color: theme.palette.primary.light,
                  }}
                >
                  {props.step.stepTemplate.displayName}
                </Typography>
                {props.showNittyGrittyDetails && (
                  <Typography variant="subtitle2" sx={{
                    fontSize: 10,
                    color: theme.palette.secondary.dark,
                    textAlign: "left" as "left",
                    padding: "2px",
                  }}>
                    {props.step.scanAnalysisStep.id}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap" as "wrap",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2" sx={{fontSize: 14}}>
                  {props.step.stepTemplate.description}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap" as "wrap",
                  alignItems: "center",
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap" as "wrap",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: "right" ,
                    marginRight: "2px",
                    fontSize: 14,
                    color: theme.palette.primary.light
                  }}
                >
                  Status:
                </Typography>

                <AiqTooltip
                  title={
                    <span>
                      {props.step.timeOfLastStatusUpdate && (
                        <React.Fragment>
                          <p>{props.step.averagePercentComplete}% complete</p>
                          <p>
                            Last update:{" "}
                            {props.step.timeOfLastStatusUpdate &&
                              getFormattedDateFromString(props.step.timeOfLastStatusUpdate, dateFormatWithTime)}
                          </p>
                        </React.Fragment>
                      )}
                      {!props.step.timeOfLastStatusUpdate && <p>No Automation History</p>}
                    </span>
                  }
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                      color: getStatusColor(props.step)
                   }}
                  >
                    {props.step.statusDisplayString}
                  </Typography>
                </AiqTooltip>

                {(props.step.isInProgress || props.step.isWaiting || props.step.hasErrors) &&
                  props.showNittyGrittyDetails && (
                    <AiqTooltip
                      title="Manually Mark Complete"
                      onClick={() => handleMarkCompleteClicked(props.step!)}
                    >
                      <IconButton size="large">
                        <SkipNext />
                      </IconButton>
                    </AiqTooltip>
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                sx={{
                  display: "flex",
                  flexWrap: "wrap" as "wrap",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle2" sx={{
    textAlign: "right" ,
    marginRight: "2px",
    fontSize: 14,
    color: theme.palette.primary.light,
 }}>
                  Initiated:
                </Typography>
                <Typography variant="subtitle2" sx={{
    fontSize: 14,
    marginRight: "4px"
 }}>
                  {getFormattedDateFromString(props.step.scanAnalysisStep.initiatedDate!, dateFormatWithTime)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Content */}
        {props.step.stepTemplate.systemId === "ROI_REMOVAL"
          ? renderRoiRemovalContent()
          : renderStepDetailsContent()}
      </Paper>
      <Toast
        open={snackbarText !== undefined}
        onClose={() => setSnackbarText(undefined)}
        text={snackbarText ?? ""}
      />
    </React.Fragment>
  );
};
