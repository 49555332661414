import * as React from "react";
import { Grid, Typography, Theme, Paper, Divider, Box } from "@mui/material";
import { TimepointList } from "./TimepointList";
import _ from "lodash";
import { Study, Timepoint } from "../../app/api/aiq-api";

/**
 * This interface defines the shape of the data this component expects for its props.
 */
interface StudyDetailsProps {
  study: Study;
  timepoints: Array<Timepoint>;
}

const StudyDetailsComponent: React.FunctionComponent<StudyDetailsProps> = (
  props: StudyDetailsProps
) => {
  return (
    <React.Fragment>
      <Paper sx={{
        width: "100%",
        marginBottom: "10px"
      }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column" as "column",
          alignItems: "baseline",
          p: 2        
        }}>
          <Typography variant="h5" sx={{mr: 1}} gutterBottom>
            Details
          </Typography>
        </Box>
        <Divider />
        <Grid sx={{ margin: 1 }} container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{
              display: "flex",
              alignItems: "center"
              }}>
              <TimepointList
                timepoints={_.sortBy(props.timepoints, (tp) => tp.sequence)}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

/**
 * This is a dumb component that displays details for a List
 */
export const StudyDetails = (StudyDetailsComponent as any);
