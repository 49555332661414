import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../../../redux/store/ConfigureStore";
import {
  Grid,
  Typography,
  Divider,
  Switch,
  CircularProgress,
  Backdrop,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import { ArrowBack, Delete } from "@mui/icons-material";
import { NavigationConstants } from "../../../constants/NavigationConstants";
import { Toast } from "../../common/Toast";
import { AiqTooltip } from "../../common/AiqToolTip";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { useDeleteApiCompoundInterscanAnalysesByIdMutation } from "../../../app/api/aiq-api";
import { CompoundInterscanAnalysisDetails } from "./CompoundInterscanAnalysisDetails";
import { enhancedApi } from "../../../app/api/enhancedApi";
import { ScanAnalysisSteps } from "../ScanAnalysisSteps";
import {useParams} from 'react-router-dom';
import { useRef } from "react";
import { AiqConfigurations } from "../../../redux/aiqConfigurations";
import { SelectedCompoundInterscanAnalysisVMSelector } from "../../../app/selectors/compoundInterscanAnalysisSelectors";
import { setViewedCompoundInterscanAnalysis } from "../../../app/slices/compoundInterscanAnalysisSlice";


export const CompoundInterscanAnalysis: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  const [showNittyGrittyDetails, setShowNittyGrittyDetails] = React.useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [loadCompoundInterscanAnalysisVM]  = enhancedApi.endpoints.getApiCompoundInterscanAnalysisViewModelsById.useLazyQuery();
  const compoundInterscanAnalysisVM = useSelector((state: ApplicationState) => SelectedCompoundInterscanAnalysisVMSelector(state));
  const selectedCompoundInterscanAnalysisId = useSelector((state: ApplicationState) => state.compoundInterscanAnalysis.edit.viewedCompoundInterscanAnalysisId);
  const [deleteInterscanAnalysis] = useDeleteApiCompoundInterscanAnalysesByIdMutation({ fixedCacheKey: 'shared-compound-interscan-analysis-delete' });
  const dispatch = useDispatch();
  const params = useParams<{ slug: string }>();
  const theme = useTheme();

  const autoRefreshTimer = useRef<NodeJS.Timer | undefined>(undefined);

  const refetchInterscanAnalysis = () => {
    if(selectedCompoundInterscanAnalysisId) {
      loadCompoundInterscanAnalysisVM({id: selectedCompoundInterscanAnalysisId});
    }
  }

  const forceRefresh = () => {
    refetchInterscanAnalysis();
  }

  const createAutoRefreshTimer = () => {
    console.log("Creating auto-refresh timer for compound interscan analysis");
    autoRefreshTimer.current = setInterval(() => {
      console.log("Auto-refreshing interscan compound analysis");
      forceRefresh();
    }, AiqConfigurations.scanStatusRefreshIntervalSecs * 1000);
    return () => clearInterval(autoRefreshTimer.current);
  };

  const destroyAutoRefreshTimer = () => {
    if (autoRefreshTimer.current) {
      console.log("Destroying auto-refresh timer for compound interscan analysis");
      clearInterval(autoRefreshTimer.current);
      autoRefreshTimer.current = undefined;
    }
  };

  const resetAutoRefreshTimer = (refreshImmediately: boolean = false) => {
    destroyAutoRefreshTimer();
    if (refreshImmediately) {
      forceRefresh();
    }
    return createAutoRefreshTimer();
  };

  React.useEffect(() => {
    let analysisIdFromUrl = params.slug;
    if(analysisIdFromUrl) {
      dispatch(setViewedCompoundInterscanAnalysis(analysisIdFromUrl));
    }

    return () => {
      destroyAutoRefreshTimer();
    };
  }, []);

  React.useEffect(() => {
    resetAutoRefreshTimer(true);
  }, [selectedCompoundInterscanAnalysisId]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteClicked = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    let id = compoundInterscanAnalysisVM?.compoundInterscanAnalysis.id as string;
    setSnackbarText(`Deleting Compound Interscan Analysis ${id}`);
    deleteInterscanAnalysis({ id: id });
    navigate(`${NavigationConstants.compoundInterscanProcessingPath}?studyId=${compoundInterscanAnalysisVM?.study.id}`);
  };

  const renderDeleteConfirmationModal = () => (
    <ConfirmationDialog
      open={deleteConfirmationOpen}
      onCancel={() => setDeleteConfirmationOpen(false)}
      onConfirm={() => handleDeleteConfirmed()}
      confirmButtonLabel="Delete"
      title="Delete Compound Interscan Analysis"
      text={`Do you want to delete Interscan Analysis ${compoundInterscanAnalysisVM?.compoundInterscanAnalysis.id}
      for patient ${compoundInterscanAnalysisVM?.sourceInterscanAnalyses[0].sourceScanAnalysisGroups[0][0]?.patient.patientIdentifier}
      at timepoint ${compoundInterscanAnalysisVM?.sourceInterscanAnalyses[0].sourceScanAnalysisGroups[0][0]?.timepoint.timepointName}?
      vs.
      patient ${compoundInterscanAnalysisVM?.sourceInterscanAnalyses[0].sourceScanAnalysisGroups[0][1]?.patient.patientIdentifier}
      at timepoint ${compoundInterscanAnalysisVM?.sourceInterscanAnalyses[0].sourceScanAnalysisGroups[0][1]?.timepoint.timepointName}?`}
    ></ConfirmationDialog>
  );

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          color: "#fff",
        }}
        open={!compoundInterscanAnalysisVM}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {compoundInterscanAnalysisVM && (
        <React.Fragment>
          <Grid data-cy="CompoundInterscanAnalysis" container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ mr: 1}} gutterBottom>
                <IconButton onClick={() => handleBack()} color="primary" size="large">
                  {" "}
                  <ArrowBack />
                </IconButton>
              </Typography>
              <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography
                      variant="h5"
                      sx={{mr: 1}}
                      gutterBottom
                    >
                      Compound Interscan Analysis
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      sx={{mr: 1}}
                      gutterBottom
                    >
                     {compoundInterscanAnalysisVM.compoundInterscanAnalysis.id}
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-end"
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{textAlign: "right" as "right",
                  marginRight: "2px",
                  fontSize: 14,
                  color: theme.palette.primary.light
                  }}>
                  Show All Details:
                </Typography>
                <Switch
                  data-cy="ShowAllDetailsButton"
                  size="small"
                  checked={showNittyGrittyDetails}
                  onChange={(event) => {
                    setShowNittyGrittyDetails(event.target.checked);
                  }}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />{" "}
                <AiqTooltip title="Delete Compound Interscan Analysis">
                  <IconButton
                    data-cy="DeleteScanAnalysisButton"
                    onClick={() => handleDeleteClicked()}
                    sx={{ ml:3, mr: 2}}
                    size="large">
                    <Delete />
                  </IconButton>
                </AiqTooltip>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <CompoundInterscanAnalysisDetails
            compoundInterscanAnalysisVM={compoundInterscanAnalysisVM}
            showNittyGrittyDetails={showNittyGrittyDetails}
          />
          <ScanAnalysisSteps
            scanAnalysisVM={compoundInterscanAnalysisVM}
            showNittyGrittyDetails={showNittyGrittyDetails}
            onForceRefresh={() => refetchInterscanAnalysis()}
          />
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ''}
          />
          {renderDeleteConfirmationModal()}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
