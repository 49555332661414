import * as React from "react";
import {
  Typography,
  Paper,
  Backdrop,
  CircularProgress,
  useTheme,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ScanAnalysisStepArtifactViewModel } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as copy from "copy-to-clipboard";
import { AiqTooltip } from "../common/AiqToolTip";
import { Toast } from "../common/Toast";

interface IJsonArtifactPreviewPopupProps {
  artifact: ScanAnalysisStepArtifactViewModel;
  onClose: () => void;
}

/**
 * This popup shows a formatted read-only preview of a JSON artifact
 */
export const JsonArtifactPreviewPopup: React.FunctionComponent<IJsonArtifactPreviewPopupProps> = (props) => {
  const theme = useTheme();
  const { data, error, isLoading } =
    enhancedApi.endpoints.getApiScanProcessingOutputsJsonContentByRelativePath.useQuery(
      { fileRelativePath: props.artifact.relativePath! },
      { refetchOnMountOrArgChange: 600 }
    );
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xl"
      onClose={() => props.onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        {props.artifact.fileName}
        <Box>
          {data && (
            <AiqTooltip title={"Copy to Clipboard"}>
              <IconButton
                color="primary"
                onClick={() => {
                  setSnackbarText("JSON copied to clipboard");
                  copy(JSON.stringify(data, null, 2));
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </AiqTooltip>
          )}
        </Box>
      </DialogTitle>
      <DialogContent data-cy="DtoContents">
        <Box sx={{ minHeight: "200px", padding: "10px" }}>
          <Backdrop sx={{ zIndex: theme.zIndex.drawer + 1, color: "#fff" }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {data && (
            <Box>
              <Typography component={"pre"}>{JSON.stringify(data, null, 2)}</Typography>
            </Box>
          )}
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ""}
            autoHideDurationMillisecs={6000}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          variant="contained"
          color="secondary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
