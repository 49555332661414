import { CircularProgress, useTheme, Box } from "@mui/material";
import {
  CheckCircleOutline,
  PanTool,
  PlayCircleFilled,
  Schedule,
  Warning,
  WarningAmber
} from "@mui/icons-material";
import React from "react";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { AiqTooltip } from "../common/AiqToolTip";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { dateFormatWithTime, getFormattedDateFromString } from "../../app/utilities/dateUtilities";
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";


interface IScanProcessingStatusIndicatorProps {
  scanAnalysisVM: ClientSideSingleScanAnalysisViewModel | ClientSideInterscanAnalysisViewModel | ClientSideCompoundInterscanAnalysisViewModel;
}

/**
 * This component displays a visual indicator of the current status of the given scan.
 * @param props The input props holding the collection of Studies to list
 */
export const ScanProcessingStatusIndicator: React.FunctionComponent<IScanProcessingStatusIndicatorProps> = (props) => {
  const theme = useTheme();

  const IndicatorWrapper = (props: any) => {
    return (
      <Box
        sx={{
          textAlign: "right",
          paddingRight: "4px",
          fontSize: 15,
        }}
      >
        {props.children}
      </Box>
    )
  }

  const renderProgressIndicator = () => {
    if (
      isNaN(props.scanAnalysisVM.currentStepPercentComplete) ||
      props.scanAnalysisVM.currentStepPercentComplete === 0
    ) {
      return (
        <React.Fragment>
          <AiqTooltip title={`0% complete`}>
            <CircularProgress
              variant="determinate"
              value={100}
              color="secondary"
              size={15}
              thickness={2}
            />
          </AiqTooltip>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <AiqTooltip
            title={
              <span>
                <p>{props.scanAnalysisVM.currentStepPercentComplete}% complete</p>
                <p>
                  Last update:{" "}
                  {props.scanAnalysisVM.currentStepTimeOfLastStatusUpdate &&
                    getFormattedDateFromString(props.scanAnalysisVM.currentStepTimeOfLastStatusUpdate.toString(), dateFormatWithTime)}
                </p>
              </span>
            }
          >
            <CircularProgress
              data-cy="scan-progress-indicator"
              variant="determinate"
              value={props.scanAnalysisVM.currentStepPercentComplete}
              color="inherit"
              size={15}
              thickness={15}
            />
          </AiqTooltip>
        </React.Fragment>
      );
    }
  }

  const renderWaitingndicator = () => {
    return (
      <React.Fragment>
        <AiqTooltip title={`Waiting for Processing`}>
          <Schedule
            data-cy="scan-waiting-indicator"
            fontSize="inherit"
            sx={{ color: theme.palette.inProgress.main }}
          />
        </AiqTooltip>
      </React.Fragment>
    );
  }

  const renderCompleteCheckmark = () => {
    return (
      <React.Fragment>
        <AiqTooltip title="Step Complete">
          <CheckCircleOutline
            data-cy="scan-complete-indicator"
            fontSize="inherit"
            sx={{ color: theme.palette.success.main }}
          />
        </AiqTooltip>
      </React.Fragment>
    );
  }

  const renderReadyIcon = () => {
    return (
      <React.Fragment>
        <AiqTooltip title="Ready for next automated step">
          <PlayCircleFilled
            data-cy="scan-ready-for-next-indicator"
            fontSize="inherit"
            sx={{ color: theme.palette.success.light }}
          />
        </AiqTooltip>
      </React.Fragment>
    );
  }

  const renderManualInprogresss = () => {
    return (
      <React.Fragment>
        <AiqTooltip title="Manual step waiting for completion">
          <PanTool
            data-cy="scan-manual-in-progress-indicator"
            fontSize="inherit"
            sx={{ color: theme.palette.inProgress.main }}
          />
        </AiqTooltip>
      </React.Fragment>
    );
  }

  const renderErrorIcon = () => {
    return (
      <React.Fragment>
        <AiqTooltip title={props.scanAnalysisVM.statusDisplayString}>
          <Warning
            data-cy="scan-error-indicator"
            fontSize="inherit"
            sx={{ color: theme.palette.error.light }}
          />
        </AiqTooltip>
      </React.Fragment>
    );
  };

  const renderWarningIcon = () => {
    return (
      <React.Fragment>
        <AiqTooltip title="This scan analysis has warnings">
          <WarningAmber
            data-cy="scan-warning-indicator"
            fontSize="inherit"
            sx={{ color: theme.palette.warning.main }}
          />
        </AiqTooltip>
      </React.Fragment>
    );
  };

  const warningIcon: React.ReactNode | null =
    props.scanAnalysisVM.hasWarningsOnOrBeforeCurrentStep
      ? renderWarningIcon()
      : null;

  let indicatorIcon: React.ReactNode;
  if (props.scanAnalysisVM.currentStepHasError) {
    indicatorIcon = renderErrorIcon();
  } else if (props.scanAnalysisVM.canMoveToNextStep) {
    indicatorIcon = renderReadyIcon();
  } else if (props.scanAnalysisVM.currentStepComplete) {
    indicatorIcon = renderCompleteCheckmark();
  } else if (props.scanAnalysisVM.currentStepIsWaiting) {
    indicatorIcon = renderWaitingndicator();
  } else if (props.scanAnalysisVM.currentStep.isAutomated && (props.scanAnalysisVM.currentStep.tasks?.length ?? 0) > 0) {
    indicatorIcon = renderProgressIndicator();
  } else {
    indicatorIcon = renderManualInprogresss();
  }

  return (
    <IndicatorWrapper>
      {warningIcon}
      {indicatorIcon}
    </IndicatorWrapper>
  );
}


