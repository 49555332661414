import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface ToastProps {
  open: boolean;
  onClose: () => void;
  text: string;
  autoHideDurationMillisecs?: number | undefined
}

/**
 * This is a little functional component to show a toast message that auto-closes
 * @param props open, onClose and text to display
 */
export const Toast: React.FunctionComponent<ToastProps> = (props) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={props.open}
      onClose={props.onClose}
      autoHideDuration={props.autoHideDurationMillisecs ?? 3000}
      message={props.text}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};
