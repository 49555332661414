import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationState } from "../../redux/store/ConfigureStore";

import { IPlatformUserFormViewModel, PlatformUserForm } from "./PlatformUserForm";
import { Typography, Paper, Divider, Container, CircularProgress, useTheme, Box } from "@mui/material";
import { Toast } from "../common/Toast";
import { useGetApiPlatformUsersQuery } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";

/**
 * This is a connected container component meant to host dumb components displaying UI for a Platform User.
 */
export const ManagePlatformUser: React.FunctionComponent = (props) => {
  const params = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [selectedUserId, setSelectedUserId] = React.useState<string | undefined>(undefined);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [userSaveError, setUserSaveError] = React.useState<string | undefined>(undefined);
  const [rolesSaveError, setRolesSaveError] = React.useState<string | undefined>(undefined);
  const [doAddUser, addUserResult] = enhancedApi.endpoints.postApiPlatformUsers.useMutation();
  const [doUpdateUser, updateUserResult] = enhancedApi.endpoints.putApiPlatformUsersById.useMutation();
  const [doAddUserRole, addUserRoleResult] = enhancedApi.endpoints.postApiPlatformUsersByIdRoles.useMutation();
  const [doDeleteUserRole, deleteUserRoleResult] = enhancedApi.endpoints.deleteApiPlatformUsersByIdRolesAndRoleId.useMutation();
  const selectedUser = useSelector((state: ApplicationState) => enhancedApi.endpoints.getApiPlatformUsersById.select({id: selectedUserId!})(state));
  const selectedUserRoles = useSelector((state: ApplicationState) => enhancedApi.endpoints.getApiPlatformUsersByIdRoles.select({id: selectedUserId!})(state));
  const {refetch: refetchPlatformUsers} = useGetApiPlatformUsersQuery();
  const [loadSelectedUserRoles]  = enhancedApi.endpoints.getApiPlatformUsersByIdRoles.useLazyQuery();
  const [loadSelectedUser]  = enhancedApi.endpoints.getApiPlatformUsersById.useLazyQuery();
  const {data: allRoles} = enhancedApi.endpoints.getApiPlatformRoles.useQuery();
  const theme = useTheme();

  React.useEffect(() => {
    if(addUserResult.isSuccess) {
      setSnackbarText("Add successful!");  
      refetchPlatformUsers();
      setSelectedUserId(addUserResult.data.id);
    }
    else if (addUserResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(addUserResult);
      setSnackbarText(`An error occurred while trying add the user - ${errorMessage}`);
      setUserSaveError(errorMessage);
    }     
  }, [addUserResult]);

  React.useEffect(() => {
    if(updateUserResult.isSuccess) {
      setSnackbarText("Update successful!");  
      refetchPlatformUsers();
    }
    else if (updateUserResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(updateUserResult);
      setSnackbarText(`An error occurred while trying update the user - ${errorMessage}`);
      setUserSaveError(errorMessage);
    }     
  }, [updateUserResult]);

  React.useEffect(() => {

    if(addUserRoleResult.isSuccess) {
      setSnackbarText("Update successful!");  
      loadSelectedUserRoles({id: selectedUserId!});
    }
    else if (addUserRoleResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(addUserRoleResult);
      setSnackbarText(`An error occurred while trying update the user roles - ${errorMessage}`);
      setRolesSaveError(errorMessage);
    }     
  }, [addUserRoleResult]);

  React.useEffect(() => {
    if(deleteUserRoleResult.isSuccess) {
      setSnackbarText("Update successful!");  
      loadSelectedUserRoles({id: selectedUserId!});
    }
    else if (deleteUserRoleResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(deleteUserRoleResult);
      setSnackbarText(`An error occurred while trying update the user roles - ${errorMessage}`);
      setRolesSaveError(errorMessage);
    }     
  }, [deleteUserRoleResult]);

  React.useEffect(() => {
    if(selectedUserId !== params.slug){
      setSelectedUserId(params.slug);
    }
  }, [])

  React.useEffect(() => {
    if(selectedUserId && selectedUserId !== selectedUser?.data?.id) {
      loadSelectedUser({id: selectedUserId});
      loadSelectedUserRoles({id: selectedUserId});
    }
  }, [selectedUserId])

  
  /**
   * Handle the save event from the form
   * @param formData The data from the submitted form
   */
  const handleSave = (formData: IPlatformUserFormViewModel) => {
    var currentActiveRoleIds = selectedUserRoles.data?.map((r) => r.id as string) ?? [];
    var selectedRoleIds = formData.activeRoleIds;

    var roleIdsNeedingRemoval: Array<string> = currentActiveRoleIds.filter(
      (currentRole) => !selectedRoleIds.includes(currentRole)
    );

    var roleIdsNeedingAddition: Array<string> = selectedRoleIds.filter(
      (selectedRole) => !currentActiveRoleIds.includes(selectedRole)
    );

    if(selectedUserId) {
      doUpdateUser({
        id: selectedUserId,
        platformUser: {
          platformUserName: formData.platformUserName,
          externalIdentityProviderId: formData.externalIdentityProviderId
        }
      });
    } else {
      doAddUser({
        platformUser: {
          platformUserName: formData.platformUserName,
          externalIdentityProviderId: formData.externalIdentityProviderId
        }
      });
    }

    roleIdsNeedingRemoval.forEach((roleToRemove) => doDeleteUserRole({ id: selectedUserId!, roleId: roleToRemove}));
    roleIdsNeedingAddition.forEach((roleToAdd) => doAddUserRole(
      { id: selectedUserId!, body: `"${roleToAdd}"`}
    ) );
}

  const handleCancel = () => {
    navigate("/platform-users");
  }


  /**
   * This is the main render function for this component
   */
    if (userSaveError || rolesSaveError) {
      return (
        <React.Fragment>
          {userSaveError && (
            <Container sx={{
              textAlign: "center" as "center",
              padding: "20px",
              color: theme.palette.error.main,
            }}>
              {userSaveError}
            </Container>
          )}
          {rolesSaveError && (
            <Container sx={{
              textAlign: "center" as "center",
              padding: "20px",
              color: theme.palette.error.main,
            }}>
              {rolesSaveError}
            </Container>
          )}
        </React.Fragment>
      );
    } else if (selectedUser.data && selectedUserRoles.data && allRoles) {
      return (
        <React.Fragment>
          <Paper sx={{width: "100%"}}>
            <Box sx={{
              display: "flex",
              flexDirection: "column" as "column",
              alignItems: "baseline",
              p: 2
            }}>
              <Typography
                variant="h5"
                sx={{mr: 1}}
                gutterBottom
              >
                Edit Platform User
              </Typography>
              <Typography variant="subtitle2">{selectedUserId}</Typography>
            </Box>
            <Divider />
            <PlatformUserForm
              isLoading={selectedUser.isLoading || selectedUserRoles.isLoading}
              isSaving={addUserResult.isLoading || updateUserResult.isLoading || addUserRoleResult.isLoading || deleteUserRoleResult.isLoading}
              user={selectedUser.data}
              activeRoles={selectedUserRoles.data}
              allRoles={allRoles}
              error={userSaveError}
              onSave={(data: IPlatformUserFormViewModel) => handleSave(data)}
              onCancel={() => handleCancel()}
            />
          </Paper>
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ""}
          />
        </React.Fragment>
      );
    } else {
      return (
        <Container sx={{padding: "20px", textAlign: "center" }}>
          <CircularProgress />
        </Container>
      );
    }

  
}
