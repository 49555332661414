import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AiqConfigurations } from "../../redux/aiqConfigurations";


/***
 * This slice manages redux state relating to Interscan Analyses
 */

export const scanAnalysisSlice = createSlice({
  name: "scanAnalysisSlice",
  initialState: {
    edit: {
      viewedScanAnalysisId: undefined,
    },
    singleScanAnalysisPageNumber: 0,
    interscanAnalysisPageNumber: 0,
    compoundInterscanAnalysisPageNumber: 0,
    singleScanAnalysisPatientFilter: undefined,
    interscanAnalysisPatientFilter: undefined,
    compoundInterscanAnalysisPatientFilter: undefined,
    selectedStudyId: undefined,
    singleScanAnalysisPageSize: AiqConfigurations.scanScanAnalysisPageSize,
    interscanAnalysisPageSize: AiqConfigurations.interscanAnalysisPageSize,
    compoundInterscanAnalysisPageSize: AiqConfigurations.compoundInterscanAnalysisPageSize
  } as scanAnalysisState,
  reducers: {
    setViewedScanAnalysis: (state, action: PayloadAction<string>) => {
      state.edit.viewedScanAnalysisId = action.payload;
    },
    setSingleScanAnalysisPageNumber: (state, action: PayloadAction<number>) => {
      state.singleScanAnalysisPageNumber = action.payload;
    },
    setInterscanAnalysisPageNumber: (state, action: PayloadAction<number>) => {
      state.interscanAnalysisPageNumber = action.payload;
    },
    setCompoundInterscanAnalysisPageNumber: (state, action: PayloadAction<number>) => {
      state.compoundInterscanAnalysisPageNumber = action.payload;
    },
    setSingleScanAnalysisPatientFilter: (state, action: PayloadAction<string | undefined>) => {
      state.singleScanAnalysisPatientFilter = action.payload;
      state.singleScanAnalysisPageNumber = 0;
    },
    setInterscanAnalysisPatientFilter: (state, action: PayloadAction<string | undefined>) => {
      state.interscanAnalysisPatientFilter = action.payload;
      state.interscanAnalysisPageNumber = 0;
    },
    setCompoundInterscanAnalysisPatientFilter: (state, action: PayloadAction<string | undefined>) => {
      state.compoundInterscanAnalysisPatientFilter = action.payload;
      state.compoundInterscanAnalysisPageNumber = 0;
    },
    setSelectedStudy: (state, action: PayloadAction<string | undefined>) => {
      state.selectedStudyId = action.payload;
      state.singleScanAnalysisPageNumber = 0;
      state.interscanAnalysisPageNumber = 0;
      state.singleScanAnalysisPatientFilter = undefined;
      state.interscanAnalysisPatientFilter = undefined;
    }
  },
});

export interface scanAnalysisState {
  edit: {
    /**
     * The ID of the currently viewed Scan Analysis
     */
    viewedScanAnalysisId: string | undefined;
  };
  singleScanAnalysisPageNumber: number;
  interscanAnalysisPageNumber: number;
  compoundInterscanAnalysisPageNumber: number;
  singleScanAnalysisPageSize: number;
  interscanAnalysisPageSize: number;
  compoundInterscanAnalysisPageSize: number;
  singleScanAnalysisPatientFilter: string | undefined;
  interscanAnalysisPatientFilter: string | undefined;
  compoundInterscanAnalysisPatientFilter: string | undefined;
  selectedStudyId: string | undefined;

}

export const { 
  setViewedScanAnalysis, 
  setSingleScanAnalysisPageNumber, 
  setInterscanAnalysisPageNumber, 
  setCompoundInterscanAnalysisPageNumber, 
  setSingleScanAnalysisPatientFilter, 
  setInterscanAnalysisPatientFilter,
  setCompoundInterscanAnalysisPatientFilter,
  setSelectedStudy } = scanAnalysisSlice.actions;

export const scanAnalysisReducer = scanAnalysisSlice.reducer;
