import { Configuration as MsalConfiguration } from "@azure/msal-browser";

/**
 * These methods wrap the retrieval of configuration values from the environment variables
 */
export const AiqConfigurations = {
  /***
   * Gets the OAuth scope, which defines what pieces of information the OAuth provider will
   * return in the tokens. It must match the configuration of the B2C tenant.
   */
  get oAuthScope(): string {
    const valueFromEnv: string | undefined = process.env.REACT_APP_OAUTH_SCOPE;
    if (!valueFromEnv) {
      throw new Error("Failed to load OAuth Scope from environment variable");
    }
    return valueFromEnv;
  },

  get baseUrl(): string {
    const valueFromEnv: string | undefined = process.env.REACT_APP_AIQ_API_BASE_PATH;
    if (!valueFromEnv) {
        throw new Error(
            "Failed to load Base URL from environment variable REACT_APP_AIQ_API_BASE_PATH"
        );
    }
    return valueFromEnv;
  },

  get scanPackageBaseUrl(): string {
    const valueFromEnv: string | undefined = process.env.REACT_APP_AIQ_SCAN_PACKAGE_BASE_URL;
    if (!valueFromEnv) {
        throw new Error(
            "Failed to load Scan Package URL from environment variable REACT_APP_AIQ_SCAN_PACKAGE_BASE_URL"
        );
    }
    return valueFromEnv;
  },

  /**
   * The OAuth configuration that the Microsoft Authentication Library agent will use
   * to communicate with our OAuth provider (Azure B2C)
   */
  get oAuthConfiguration(): MsalConfiguration {
    //  Make sure all our expected values are accessible
    if (!process.env.REACT_APP_OAUTH_CLIENT_ID) {
      throw new Error(
        "Failed to load the OAuth Client ID from the environment variables"
      );
    }
    if (!process.env.REACT_APP_OAUTH_AUTHORITY) {
      throw new Error(
        "Failed to load the OAuth Authority from the environment variables"
      );
    }
    if (!process.env.REACT_APP_REDIRECT_URI) {
      throw new Error(
        "Failed to load the OAuth Redirect URI from the environment variables"
      );
    }

    const config = {
      auth: {
        // The client ID (Application ID) for our B2C tenant application
        clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,

        // https://<tenant name>/<tenant/directory id>/<name of the login flow policy>
        authority: process.env.REACT_APP_OAUTH_AUTHORITY,
        knownAuthorities: ["https://aiqauthprod.b2clogin.com"],

        // I think the Authority param is just telling it which login flow to use - we're not validating it
        validateAuthority: false,

        // The URL that the Azure tenant will send us back to when it's done logging in or out - should point at our app
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
      },
      cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
      },
    } as MsalConfiguration;

    return config;
  },

  /**
   * A display name for the current environment (QA/Dev/Prod/etc)
   */
  get environmentName(): string {
    const valueFromEnv: string | undefined = process.env.REACT_APP_ENVIRONMENT_NAME;
    if (!valueFromEnv) {
      throw new Error(
        "Failed to load Environment Name from environment variable REACT_APP_ENVIRONMENT_NAME"
      );
    }
    return valueFromEnv;
  },

  /**
   * A the current version of the app
   */
  get version(): string {
    const valueFromEnv: string | undefined = process.env.REACT_APP_VERSION;
    if (!valueFromEnv) {
      throw new Error(
        "Failed to load App Version from environment variable REACT_APP_VERSION"
      );
    }
    return valueFromEnv;
  },

  /**
   * The number of the build (pipeline) the app was deployed with
   */
  get buildNumber(): string {
    const valueFromEnv: string | undefined = process.env.REACT_APP_BUILD_NUMBER;
    if (!valueFromEnv) {
      throw new Error(
        "Failed to load Build Number from environment variable REACT_APP_BUILD_NUMBER"
      );
    }
    return valueFromEnv;
  },

  /**
   * The date/time this version of the app was built
   */
  get buildTime(): string {
    const valueFromEnv: string | undefined = process.env.REACT_APP_BUILD_DATETIME;
    if (!valueFromEnv) {
      throw new Error(
        "Failed to load Build Date/Time from environment variable REACT_APP_BUILD_DATETIME"
      );
    }
    return valueFromEnv;
  },

  /**
   * The number of Scan Analysis View Models to retrieve at one time in the Scan Processing Dashboard
   */
  get scanScanAnalysisPageSize(): number {
    const valueFromEnv: string | undefined =
      process.env.REACT_APP_SCAN_ANALYSIS_PAGE_SIZE;
    const numValue = valueFromEnv ? parseInt(valueFromEnv) : undefined;
    if (!numValue) {
      throw new Error(
        "Failed to load Scan Analysis Page Size from environment variable REACT_APP_SCAN_ANALYSIS_PAGE_SIZE"
      );
    }
    return numValue;
  },

    /**
   * The number of Interscan Analysis View Models to retrieve at one time in the Scan Processing Dashboard
   */
     get interscanAnalysisPageSize(): number {
      const valueFromEnv: string | undefined =
        process.env.REACT_APP_INTERSCAN_ANALYSIS_PAGE_SIZE;
      const numValue = valueFromEnv ? parseInt(valueFromEnv) : undefined;
      if (!numValue) {
        throw new Error(
          "Failed to load Interscan Analysis Page Size from environment variable REACT_APP_INTERSCAN_ANALYSIS_PAGE_SIZE"
        );
      }
      return numValue;
    },
    
   /**
   * The number of Interscan Analysis View Models to retrieve at one time in the Scan Processing Dashboard
   */
    get compoundInterscanAnalysisPageSize(): number {
      const valueFromEnv: string | undefined = process.env.REACT_APP_COMPOUND_INTERSCAN_ANALYSIS_PAGE_SIZE;
      const numValue = valueFromEnv ? parseInt(valueFromEnv) : undefined;
      if (!numValue) {
        throw new Error(
          "Failed to load Compound Interscan Analysis Page Size from environment variable REACT_APP_COMPOUND_INTERSCAN_ANALYSIS_PAGE_SIZE"
        );
      }
      return numValue;
    },
    
    /**
    * The amount of time between polling for scan analysis status updates in the scan processing dashboard
    */
     get scanStatusRefreshIntervalSecs(): number {
      const valueFromEnv: string | undefined =
        process.env.REACT_APP_SCAN_STATUS_REFRESH_INTERVAL_SECS;
      const numValue = valueFromEnv ? parseInt(valueFromEnv) : undefined;
      if (!numValue) {
        throw new Error(
          "Failed to load Scan Status Refresh Interval from environment variable REACT_APP_BUILD_NUMBER"
        );
      }
      return numValue;
    },

  /**
   * Whether or not Authentication is enabled. Only ever disabled for E2E testing purposes where the
   * backend API is mocked. Regular runtime app will not function normally without authentication.
   */
  get authenticationEnabled(): boolean {
    // @ts-ignore
    if (window.Cypress) {
        return false;
    }

    const valueFromEnv: string | undefined = process.env.REACT_APP_AUTH_ENABLED;
    return valueFromEnv !== "false";
  },

   /**
   * Whether or not Side-load requires official acceptance, or can be optional and free form
   */
     get sideLoadAcceptanceRequired(): boolean { 
      const valueFromEnv: string | undefined = process.env.REACT_APP_SIDE_LOAD_ACCEPTANCE_REQUIRED;
      return valueFromEnv !== "false";
    },

};
