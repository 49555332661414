import {Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from "@mui/system";
import React from 'react';

export const AiqTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} enterDelay={ props.enterDelay ?? 500} enterNextDelay={ props.enterNextDelay ?? 250} classes={{ popper: className }}  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 1,
    fontSize: "1em"
  },
}));