import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Home, People, Assignment, PlaylistPlay, Compare, Article } from "@mui/icons-material";
import { Divider, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AiqTooltip } from "./AiqToolTip";
import { NavigationConstants } from "../../constants/NavigationConstants"

export const NavItems = (
  <div>
    <ListItem button>
      <AiqTooltip title="Home">
        <ListItemIcon>
          <Link component={RouterLink} to="/" underline="hover">
            <Home />
          </Link>
        </ListItemIcon>
      </AiqTooltip>
      <Link component={RouterLink} to="/" underline="hover">
        <ListItemText primary="Home" />
      </Link>
    </ListItem>
    <ListItem button>
      <AiqTooltip title="Platform Users">
        <ListItemIcon>
          <Link
            component={RouterLink}
            to={NavigationConstants.platformUserPath}
            underline="hover">
            <People />
          </Link>
        </ListItemIcon>
      </AiqTooltip>
      <Link
        component={RouterLink}
        to={NavigationConstants.platformUserPath}
        underline="hover">
        <ListItemText primary="Platform Users" />
      </Link>
    </ListItem>
    <ListItem button>
      <AiqTooltip title="Studies">
        <ListItemIcon>
          <Link
            component={RouterLink}
            to={NavigationConstants.studiesPath}
            underline="hover">
            <Assignment />
          </Link>
        </ListItemIcon>
      </AiqTooltip>
      <Link
        component={RouterLink}
        to={NavigationConstants.studiesPath}
        underline="hover">
        <ListItemText primary="Studies" />
      </Link>
    </ListItem>
    <Divider />
    <ListItem button>
      <AiqTooltip title="Single Scan Processing">
        <ListItemIcon>
          <Link
            component={RouterLink}
            to={NavigationConstants.singleScanProcessingPath}
            underline="hover">
            <PlaylistPlay />
          </Link>
        </ListItemIcon>
      </AiqTooltip>
      <Link
        component={RouterLink}
        to={NavigationConstants.scanProcessingApplicationIdQueryStringKey}
        underline="hover">
        <ListItemText primary="Single Scan Processing" />
      </Link>
    </ListItem>
    <ListItem button>
      <AiqTooltip title="Interscan Processing">
        <ListItemIcon>
          <Link
            component={RouterLink}
            to={NavigationConstants.interscanProcessingPath}
            underline="hover">
            <Compare />
          </Link>
        </ListItemIcon>
      </AiqTooltip>
      <Link
        component={RouterLink}
        to={NavigationConstants.scanProcessingApplicationIdQueryStringKey}
        underline="hover">
        <ListItemText primary="Interscan Processing" />
      </Link>
    </ListItem>
    <Divider />
    <ListItem>
      <AiqTooltip title="Report Processing">
        <ListItemIcon>
          <Link
            component={RouterLink}
            to={NavigationConstants.compoundInterscanProcessingPath}
            underline="hover">
            <Article />
          </Link>
        </ListItemIcon>
      </AiqTooltip>
      <Link
        component={RouterLink}
        to={NavigationConstants.compoundInterscanProcessingPath}
        underline="hover">
        <ListItemText primary="Report Processing" />
      </Link>
    </ListItem>
  </div>
);
