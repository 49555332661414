import * as React from "react";
import { Typography, Theme, FormControl, TextField, InputLabel, InputAdornment, IconButton, Divider } from "@mui/material";
import { Clear, FilterAlt, Search } from "@mui/icons-material";
import { AiqTooltip } from "../common/AiqToolTip";

export interface IFilterMenuProps {
  onFilterChange: (studyId: string | undefined) => void;
  filterValue: string | undefined;
}

/**
 * A simple functional component that displays a menu for selecting studies
 */
export const FilterMenu: React.FunctionComponent<IFilterMenuProps> = (
  props
) => {
  const [textFieldValue, setTextFieldValue] = React.useState<string> ('');

  React.useEffect(() => {
    setTextFieldValue(props.filterValue ?? "");
   }, [props.filterValue])
 

  return (
    <TextField 
      data-cy="FilterMenu"
      sx={{width: "100%"}} 
      size="small" 
      id="outlined-basic" 
      label="Patient Filter" 
      variant="outlined" 
      value={textFieldValue}
      onKeyDown={(e) => { 
        if(e.key == 'Enter' && textFieldValue != props.filterValue) {
          props.onFilterChange(textFieldValue);
        }
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTextFieldValue(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FilterAlt />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {props.filterValue && props.filterValue.length > 0 && (
              <AiqTooltip title="Clear patient filter">
                <IconButton data-cy="FilterClearButton" disableRipple onClick={() => props.onFilterChange(undefined)}>
                  <Clear />
                </IconButton>
              </AiqTooltip>
            )}
            <Divider sx={{ height: 36, m: 0.5 }} orientation="vertical" />
            <IconButton 
              color="primary"
              disabled={textFieldValue.length == 0 || textFieldValue == props.filterValue}
              onClick={() => props.onFilterChange(textFieldValue)}
              disableRipple
              sx={{ p: '10px', pr: '0px', pl: '5px' }}>
              <AiqTooltip title="Apply patient filter">
                <Search />
              </AiqTooltip>
            </IconButton>
          </InputAdornment>
        )
      }}
      />
  );
};
