import {
  ScanAnalysisStepViewModel,
  InterscanApplicationTaskTemplate,
  InterscanApplicationTaskTemplateApplicationStepTemplate,
  SingleScanApplicationTaskTemplateApplicationStepTemplate,
  SingleScanApplicationTaskTemplate } from "../../../app/api/aiq-api";
import { ScanAnalysisStep } from "../../../app/api/aiq-api";
import { ClientSideBaseProcessingTaskViewModel } from "./ClientSideBaseAnalysisTaskViewModel";

/**
 * A base client-side view model for displaying UIs for Interscan or Single Scan analysis tasks.
 */
export abstract class ClientSideBaseAnalysisStepViewModel<TTaskTemplateViewmodel extends SingleScanApplicationTaskTemplate | InterscanApplicationTaskTemplate>  {
  constructor(backendViewModel: ScanAnalysisStepViewModel) {
    this.checkRequiredFieldsForNull(backendViewModel);
    this.scanAnalysisStep = backendViewModel.scanAnalysisStep!;
    this.isInProgress = backendViewModel.isInProgress!;
    this.isComplete = backendViewModel.isComplete!;
    this.isWaiting = backendViewModel.isWaiting!;
    this.hasErrors = backendViewModel.hasErrors!;
    this.isAutomated = backendViewModel.isAutomated!;
    this.timeOfLastStatusUpdate = backendViewModel.timeOfLastStatusUpdate!;
    this.averagePercentComplete = backendViewModel.averagePercentComplete!;
    this.stepSequence = backendViewModel.stepSequence!;
  }

  scanAnalysisStep!: ScanAnalysisStep;
  tasks!: ClientSideBaseProcessingTaskViewModel<TTaskTemplateViewmodel>[];
  isInProgress!: boolean;
  isComplete!: boolean;
  isWaiting!: boolean;
  hasErrors!: boolean;
  isAutomated!: boolean;
  timeOfLastStatusUpdate!: string;
  averagePercentComplete!: number;
  stepSequence!: number;
  stepTemplate!: InterscanApplicationTaskTemplateApplicationStepTemplate | SingleScanApplicationTaskTemplateApplicationStepTemplate;

  get hasWarnings(): boolean {
    return this.tasks
      .flatMap(t => t.orderedScanProcessingTaskStatuses)
      .some(ts => ts.scanProcessingTaskStatus!.kind === 'WARNING');
  }

  get statusDisplayString(): string {
    // Automated
    if (this.isAutomated) {

      if(this.hasErrors) {
        return `Error`
      }
      if(this.isComplete) {
        return 'Complete'
      }
      if(this.isInProgress) {
        return `In Progress - ${this.averagePercentComplete}%`
      }
      if(this.isWaiting) {
        return `Awaiting Processing`
      }
      return `Unknown`
    }
    // Manual
    else {
      if (this.isComplete === true) {
        return "Complete";
      } else {
        return "In Progress";
      }
    }
  }

  protected abstract checkRequiredFieldsForNull(backendViewModel: ScanAnalysisStepViewModel): void;
}
