import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";

export interface IConfirmDeleteRoiDialogResult {
  roiIds: Array<string>;
  deleteReason: string;
}

interface IConfirmDeleteRoiDialogProps {
  roiIds: Array<string>;
  open: boolean;
  onConfirm: (result: IConfirmDeleteRoiDialogResult) => void;
  onClose: () => void;
}

export const ConfirmDeleteRoiDialog: React.FunctionComponent<IConfirmDeleteRoiDialogProps> = (props) => {
  const [deleteReason, setDeleteReason] = useState<string>("");

  const commonReasonsToDelete = [
    "AI Recommendation",
    "Benign ROI",
    "Not an ROI",
    "Bladder"
  ];

  useEffect(() => {
    if (props.open) {
      setDeleteReason("");
    }
  }, [props.open])

  const roiIdsForDisplay = props.roiIds.join(",");

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
    >
      <DialogTitle>Confirm Delete ROIs</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please specify a reason to delete the selected ROIs ({roiIdsForDisplay}).
        </DialogContentText>
        <Autocomplete
          id="deleteReason"
          freeSolo
          options={commonReasonsToDelete}
          inputValue={deleteReason}
          onInputChange={(e, v) => setDeleteReason(v)}
          renderInput={(params) =>
            <TextField
              {...params}
              variant="standard"
              margin="dense"
              autoFocus
              fullWidth
              label="Reason to Delete"
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Cancel</Button>
        <Button
          onClick={() => props.onConfirm({ roiIds: props.roiIds, deleteReason: deleteReason})}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
