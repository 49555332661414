import * as React from "react";
import { Grid, Typography, Paper, Button, Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigationConstants } from "../../../constants/NavigationConstants";
import { getFormattedDateFromString } from "../../../app/utilities/dateUtilities";
import { ClientSideCompoundInterscanAnalysisViewModel } from "../../../redux/services/view-models/ClientSideCompoundInterscanAnalysisViewModel";
import { InterscanProcessingCard } from "../InterscanProcessingCard";

interface ICompoundInterscanAnalysisDetailsProps {
  compoundInterscanAnalysisVM: ClientSideCompoundInterscanAnalysisViewModel | undefined;
  showNittyGrittyDetails: boolean;
}

export const CompoundInterscanAnalysisDetails: React.FunctionComponent<ICompoundInterscanAnalysisDetailsProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleInterscanAnalysisClick = (interscanAnalysisId: string) => {
    navigate(`${NavigationConstants.interscanAnalysisPath}/${interscanAnalysisId}`);
  };

  return (
    <React.Fragment>
      {props.compoundInterscanAnalysisVM && (
        <Paper data-cy="CompoundInterscanAnalysisDetails" sx={{ width: "100%", marginBottom: "10px" }}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} sm={1}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column" as "column",
                  alignItems: "baseline",
                  p: 2,
                  color: theme.palette.primary.light,
                }}
              >
                <Typography variant="h6" sx={{ mr: 1 }} gutterBottom>
                  Details
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={11}>
              <Grid sx={{ padding: "10px" }} container spacing={1} alignItems="flex-start">
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Study:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.compoundInterscanAnalysisVM.study.studyName}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.compoundInterscanAnalysisVM.study.id}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right" as "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Application:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={4}
                  md={3}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {props.compoundInterscanAnalysisVM.applicationTemplate.displayName}
                  </Typography>
                  {props.showNittyGrittyDetails && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: 10,
                        color: theme.palette.secondary.dark,
                        textAlign: "left",
                        padding: "2px",
                      }}
                    >
                      {props.compoundInterscanAnalysisVM.applicationTemplate.systemId}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3} sm={2} md={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right" as "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Created:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={4}
                  md={2}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: 14,
                      marginRight: "4px",
                    }}
                  >
                    {getFormattedDateFromString(props.compoundInterscanAnalysisVM.compoundInterscanAnalysis.initiatedDate)}
                  </Typography>
                </Grid>
                <Grid item xs={false} sm={false} md={2}></Grid>
                <Grid item xs={5} sm={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: "right" as "right",
                      marginRight: "2px",
                      fontSize: 14,
                      color: theme.palette.primary.light,
                    }}
                  >
                    Interscan Scan Analyses
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={11}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {props.compoundInterscanAnalysisVM.sourceInterscanAnalyses.map((interscanAnalysisVM) =>(
                    <Box 
                    key = {interscanAnalysisVM.interscanAnalysis.id!}
                    sx={{ 
                      display: "flex",
                      flexDirection: "row",
                      px: .5,
                      mx: 0, 
                      background: "inherit"
                    }}>
                      <Button
                        sx={{ textTransform: "none", textAlign: "left", p: 0 }}
                        onClick={() => handleInterscanAnalysisClick(interscanAnalysisVM.interscanAnalysis.id!)}
                      >
                        <div key={interscanAnalysisVM.interscanAnalysis.id!} style={{ maxWidth: 260 }}>
                          <InterscanProcessingCard interscanAnalysisVM={interscanAnalysisVM} />
                        </div>
                      </Button>
                    </Box>
                  )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
};
