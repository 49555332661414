export class ScanProcessingConstants {
  /**
   * The names of the processing components per the component_name values in the components table of the database
   */
  static readonly stepSystemIds = {
    scanIngest: "IMPORT",
  };

  /**
   * The root folder shared by the component processors, web service
   */
  static readonly scanProcessingRootFolder = "scan-processing/";

  /**
   * The expected file suffix found on anatomical (CT) scans after DICOM load
   */
  static readonly anatomicalNrrdSuffix = "ctData.nrrd";

  /**
   * The expected file suffix found on functional (PET) scans after DICOM load
   */
  static readonly functionalNrrdSuffix = "petData.nrrd";

  /**
   * The "sex code" value for Female per the sex_code field of the sexes table in the database
   */
  static readonly femaleSexCode = "F";

  /**
   * The "sex code" value for Male per the sex_code field of the sexes table in the database
   */
  static readonly maleSexCode = "M";

  // !!!! These need to stay consistent with the cloud-basedpython component processing code's settings
  /**
   * The argument name used to pass the path to an atlas to the skeletal segmentation processing step
   */
  static readonly rigidRegistrationAtlasPathArgumentName = "atlas_path";

  /**
   * The argument name used to pass the path to a PET/Functional NRDD to the skeletal segmentation processing step
   */
  static readonly rigidRegistrationFunctionaPathArgumentName = "pet_path";

  /**
   * The argument name used to pass the path to a CT/Anatomical NRDD to the skeletal segmentation processing step
   */
  static readonly rigidRegistrationOutputLabelPrefixArgumentName = "output_label_prefix";
}
