import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Study } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { setViewedStudyIdAction } from "../../redux/actions/StudyActions";
import { IStudyFormViewModel } from "./StudyForm";
import { Container, Typography, CircularProgress, IconButton, useTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Toast } from "../common/Toast";
import { produce } from "immer";
import { StudyDetails } from "./StudyDetails";
import { StudyInfo } from "./StudyInfo";
import { NavigationConstants } from "../../constants/NavigationConstants";
import {
  UsedStudyNamesLowerCase,
  ViewedEditStudySelector,
  ViewedStudyTimepointsSelector,
} from "../../app/selectors/studySelectors";
import { getErrorMessageFromRtkMutationResult } from "../../app/api/apiUtilities";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { useNavigate, useParams } from "react-router-dom";

/**
 * This is a connected container component meant to host dumb components displaying UI for a Platform Study.
 */
export const ManageStudy: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [doUpdateStudy, updateStudyResult] = enhancedApi.endpoints.putApiStudiesById.useMutation();
  const { isLoading: timepointsLoading } = enhancedApi.endpoints.getApiTimepoints.useQuery();
  const { isLoading: studiesLoading } = enhancedApi.endpoints.getApiStudies.useQuery();
  const { data: studyStatuses, isLoading: studyStatusesLoading } = enhancedApi.endpoints.getApiStudyStatuses.useQuery();
  const study = useSelector((state: ApplicationState) => ViewedEditStudySelector(state as never));
  const usedStudyNames = useSelector(UsedStudyNamesLowerCase);
  const timepoints = useSelector(ViewedStudyTimepointsSelector);
  const params = useParams<{ slug: string }>();
  const theme = useTheme();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setViewedStudyIdAction(params.slug!));
  }, []);

  React.useEffect(() => {
    if (updateStudyResult.isSuccess) {
      setSnackbarText("Study saved!");
    } else if (updateStudyResult.isError) {
      var errorMessage = getErrorMessageFromRtkMutationResult(updateStudyResult);
      setSnackbarText(`An error occurred while trying to save the Study - ${errorMessage}`);
    }
  }, [updateStudyResult]);

  const handleSaveStudy = (studyFormData: IStudyFormViewModel) => {
    const updatedStudy: Study = produce(study!, (updatedStudy) => {
      updatedStudy.studyName = studyFormData.studyName;
      updatedStudy.principalInvestigator = studyFormData.principalInvestigator;
      updatedStudy.statusId = studyFormData.statusId;
      updatedStudy.startDate = studyFormData.startDate.toISOString();
    });

    doUpdateStudy({
      id: studyFormData.id!,
      study: updatedStudy,
    });
  };

  /**
   * This is the main render function for this component
   */
  if (study && timepoints && studyStatuses) {
    return (
      <React.Fragment>
        <Typography variant="h4" sx={{ mr: 1 }} gutterBottom>
          <IconButton onClick={() => navigate(NavigationConstants.studiesPath)} color="primary" size="large">
            {" "}
            <ArrowBack />
          </IconButton>
          Platform Study
        </Typography>
        <StudyInfo
          saving={false}
          loading={studiesLoading || studyStatusesLoading || timepointsLoading}
          study={study}
          usedStudyNames={usedStudyNames}
          studyStatuses={studyStatuses}
          onSave={(formData: IStudyFormViewModel) => handleSaveStudy(formData)}
        />

        <StudyDetails study={study} timepoints={timepoints} />
        <Toast
          open={snackbarText !== undefined}
          onClose={() => setSnackbarText(undefined)}
          text={snackbarText ?? ""}
        />
        {updateStudyResult.isError && (
          <Container
            sx={{
              textAlign: "center" as "center",
              padding: "20px",
              color: theme.palette.error.main,
            }}
          >
            {getErrorMessageFromRtkMutationResult(updateStudyResult)}
          </Container>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <Container
        sx={{
          padding: "20px",
          textAlign: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }
};
