import { studyReducer } from "../reducers/StudyReducer";
import { authSliceReducer } from "../../app/slices/authSlice";
import { interscanAnalysisReducer } from "../../app/slices/interscanAnalysisSlice";
import { compoundInterscanAnalysisReducer } from "../../app/slices/compoundInterscanAnalysisSlice";
import { scanAnalysisReducer } from "../../app/slices/scanAnalysisSlice";

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  studies: studyReducer,
  scanAnalyses: scanAnalysisReducer,
  auth: authSliceReducer,
  interscanAnalysis: interscanAnalysisReducer,
  compoundInterscanAnalysis: compoundInterscanAnalysisReducer
};
