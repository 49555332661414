export class NavigationConstants {
  static readonly scanProcessingApplicationIdQueryStringKey = "applicationId";
  static readonly scanProcessingStudyIdQueryStringKey = "studyId";
  static readonly scanProcessingPatientIdQueryStringKey = "patientId";
  static readonly platformUserPath = "/platform-users";
  static readonly studiesPath = "/studies";
  static readonly singleScanAnalysisPath = "/single-scan-analysis";
  static readonly singleScanProcessingPath = "/single-scan-processing";
  static readonly interscanAnalysisPath = "/interscan-analysis";
  static readonly interscanProcessingPath = "/interscan-processing";
  static readonly compoundInterscanAnalysisPath = "/compound-interscan-analysis";
  static readonly compoundInterscanProcessingPath = "/compound-interscan-processing";
}
