import {  createSlice,  PayloadAction} from "@reduxjs/toolkit";

/***
 * This slice manages redux state relating to Interscan Analyses
 */

export const compoundInterscanAnlysisSlice = createSlice({
  name: "compoundInterscanAnalysisSlice",
  initialState: { edit: {
    viewedCompoundInterscanAnalysisId: undefined
   } } as CompoundInterscanAnalysisState,
  reducers: {
    setViewedCompoundInterscanAnalysis: (state, action: PayloadAction<string>) => {
      state.edit.viewedCompoundInterscanAnalysisId = action.payload;
    },
  },
});

export interface CompoundInterscanAnalysisState {
  edit: {
    /**
     * The ID of the currently viewed Interscan Analysis
     */
     viewedCompoundInterscanAnalysisId: string | undefined;
    };
}


export const { setViewedCompoundInterscanAnalysis } = compoundInterscanAnlysisSlice.actions;

export const compoundInterscanAnalysisReducer = compoundInterscanAnlysisSlice.reducer