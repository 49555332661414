import { Scan, ScanAnalysisViewModel, SingleScanApplication, SingleScanApplicationTaskTemplateApplicationStepTemplate } from "../../../app/api/aiq-api";
import { Exam, Patient, ScanAnalysis, Timepoint } from "../../../app/api/aiq-api";
import { ClientSideSingleScanAnalysisStepViewModel } from "./ClientSideSingleScanAnalysisStepViewModel";
import { ClientSideBaseAnalysisViewModel } from "./ClientSideBaseScanAnalysisViewModel";
import { ScanProcessingConstants } from "../../../constants/ScanProcessingConstants";


/**
 * A client-side view model for displaying UIs for a single scan analysis.
 */
export class ClientSideSingleScanAnalysisViewModel extends ClientSideBaseAnalysisViewModel {
  constructor(backendViewModel: ScanAnalysisViewModel) {
    super(backendViewModel);
    this.checkRequiredFieldsForNull(backendViewModel);
    this.scanAnalysis = backendViewModel.scanAnalysis!;
    this.applicationTemplate = backendViewModel.singleScanApplication!;
    this.orderedScanAnalysisSteps = backendViewModel.orderedScanAnalysisSteps!.map(step => {
      return new ClientSideSingleScanAnalysisStepViewModel(step, this)}
      );
    this.patient =  backendViewModel.patient!;
    this.exam =  backendViewModel.exam!;
    this.timepoint =  backendViewModel.timepoint!;
    this.functionalScanViewModel =  backendViewModel.functionalScan;
    this.anatomicalScanViewModel =  backendViewModel.anatomicalScan!;
  }

  scanAnalysis!: ScanAnalysis;
  orderedScanAnalysisSteps!: Array<ClientSideSingleScanAnalysisStepViewModel>;
  patient!: Patient;
  exam!: Exam;
  timepoint!: Timepoint;
  functionalScanViewModel!: Scan | undefined;
  anatomicalScanViewModel!: Scan;
  applicationTemplate!: SingleScanApplication;

  /**
   * The step that this scan analysis can automatically move on to
   */
  automatedSuccessorComponentId: string | undefined;
  
  get dateCreated(): Date {
    return new Date(this.scanAnalysis.initiatedDate!);
  }

  get singleScanProcessingIsComplete(): boolean {
    return this.currentStepComplete && this.currentStep == this.orderedScanAnalysisSteps[this.orderedScanAnalysisSteps.length - 1];
  }

  protected isStepAutomated(stepSystemId: string): boolean {
    var step: SingleScanApplicationTaskTemplateApplicationStepTemplate | undefined = 
      this.applicationTemplate.steps?.find((s: SingleScanApplicationTaskTemplateApplicationStepTemplate) => s.systemId === stepSystemId);
    return step?.automated ?? false;
  }

  protected override checkRequiredFieldsForNull(backendViewModel: ScanAnalysisViewModel) {
    // There is probably a super fancy way to do this but everything I googled looked ugly and unnecessarily complicated 
    // so I went with dumb but simple, like myself - CSS20220315
    if(!backendViewModel.scanAnalysis) {
      this.throwNullArgConstructionError('scanAnalysis', backendViewModel)
     }
     if(!backendViewModel.orderedScanAnalysisSteps || backendViewModel.orderedScanAnalysisSteps.length == 0) {
      this.throwNullArgConstructionError('orderedScanAnalysisSteps', backendViewModel)
     }
     if(!backendViewModel.patient) {
      this.throwNullArgConstructionError('patient', backendViewModel)
     }
     if(!backendViewModel.exam) {
      this.throwNullArgConstructionError('exam', backendViewModel)
     }
     if(!backendViewModel.study) {
      this.throwNullArgConstructionError('study', backendViewModel)
     }
     if(!backendViewModel.timepoint) {
      this.throwNullArgConstructionError('timepoint', backendViewModel)
     }
     if(!backendViewModel.anatomicalScan) {
      this.throwNullArgConstructionError('anatomicalScan', backendViewModel)
     }
  }

  override canTransitionToStep(targetStepSystemId: string): boolean {
    // Can't transition to scan ingest, it is special
    if (targetStepSystemId === ScanProcessingConstants.stepSystemIds.scanIngest) {
      return false;
    }

    return super.canTransitionToStep(targetStepSystemId);
  }

  private throwNullArgConstructionError(nullFieldName: string, backendViewModel: ScanAnalysisViewModel) {
    throw new Error(`Can't construct client side Scan Analysis View Model for ${backendViewModel.scanAnalysis?.id} - required field ${nullFieldName} is null or empty`);
  }
}
