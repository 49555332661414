import {  createSlice,  PayloadAction} from "@reduxjs/toolkit";

/***
 * This slice manages redux state relating to Interscan Analyses
 */

export const interscanAnlysisSlice = createSlice({
  name: "interscanAnalysisSlice",
  initialState: { edit: {
    viewedInterscanAnalysisId: undefined
   } } as InterscanAnalysisState,
  reducers: {
    setViewedInterscanAnalysis: (state, action: PayloadAction<string>) => {
      state.edit.viewedInterscanAnalysisId = action.payload;
    },
  },
});

export interface InterscanAnalysisState {
  edit: {
    /**
     * The ID of the currently viewed Interscan Analysis
     */
     viewedInterscanAnalysisId: string | undefined;
    };
}


export const { setViewedInterscanAnalysis } = interscanAnlysisSlice.actions;

export const interscanAnalysisReducer = interscanAnlysisSlice.reducer