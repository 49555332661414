import { ScanAnalysisStepViewModel, InterscanApplicationTaskTemplate, InterscanApplicationTaskTemplateApplicationStepTemplate } from "../../../app/api/aiq-api";
import { ClientSideBaseAnalysisStepViewModel } from "./ClientSideBaseAnalysisStepViewModel";
import { ClientSideInterscanProcessingTaskViewModel } from "./ClientSideInterscanAnalysisTaskViewModel";
import { ClientSideInterscanAnalysisViewModel } from "./ClientSideInterscanAnalysisViewModel";

/**
 * A client-side view model for displaying UIs for an Interscan analysis.
 */
export class ClientSideInterscanAnalysisStepViewModel extends ClientSideBaseAnalysisStepViewModel<InterscanApplicationTaskTemplate> {
  constructor(backendViewModel: ScanAnalysisStepViewModel, parentScanAnalysisViewModel: ClientSideInterscanAnalysisViewModel) {
    super(backendViewModel);
    this.parentInterscanAnalysisViewModel = parentScanAnalysisViewModel;
    var stepTemplate = parentScanAnalysisViewModel.applicationTemplate.steps?.find((s: InterscanApplicationTaskTemplateApplicationStepTemplate) => s.systemId === backendViewModel.scanAnalysisStep!.applicationStepSystemId);
    if(!stepTemplate) {
      throw new Error(`Can't construct Client Side Scan Analysis Step View Model for ${backendViewModel.scanAnalysisStep?.id} - no step template with system ID ${backendViewModel.scanAnalysisStep!.applicationStepSystemId} found in application template ${parentScanAnalysisViewModel.applicationTemplate.systemId}`);
    }

    this.stepTemplate = stepTemplate;
    this.tasks = backendViewModel.tasks!.map(task => new ClientSideInterscanProcessingTaskViewModel(task, this));
  }

  protected override checkRequiredFieldsForNull(backendViewModel: ScanAnalysisStepViewModel) {
    // There is probably a super fancy way to do this but everything I googled looked ugly and unnecessarily complicated 
    // so I went with dumb but simple, like myself - CSS20220315
    if(!backendViewModel.scanAnalysisStep) {
      this.throwNullArgConstructionError('scanAnalysisStep', backendViewModel)
     }
     if(!backendViewModel.tasks) {
      this.throwNullArgConstructionError('tasks', backendViewModel)
     }
  }

  parentInterscanAnalysisViewModel!: ClientSideInterscanAnalysisViewModel;
  stepTemplate!: InterscanApplicationTaskTemplateApplicationStepTemplate;


  private throwNullArgConstructionError(nullFieldName: string, backendViewModel: ScanAnalysisStepViewModel) {
    throw new Error(`Can't construct client side Scan Analysis Step View Model for ${backendViewModel.scanAnalysisStep?.id} - required field ${nullFieldName} is null or empty`);
  }
}
