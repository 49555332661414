import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import {
  Grid,
  Typography,
  Theme,
  Divider,
  Switch,
  CircularProgress,
  Backdrop,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import { ArrowBack, Delete } from "@mui/icons-material";
import { NavigationConstants } from "../../constants/NavigationConstants";
import { Toast } from "../common/Toast";
import { AiqTooltip } from "../common/AiqToolTip";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { SelectedInterscanAnalysisVMSelector } from "../../app/selectors/interscanAnalysisSelectors";
import {
  useDeleteApiInterscanAnalysesByIdMutation
} from "../../app/api/aiq-api";
import { setViewedInterscanAnalysis } from "../../app/slices/interscanAnalysisSlice";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import { InterscanAnalysisDetails } from "./InterscanAnalysisDetails";
import { enhancedApi } from "../../app/api/enhancedApi";
import { ScanAnalysisSteps } from "./ScanAnalysisSteps";
import {useParams} from 'react-router-dom';
import { useRef } from "react";


export const InterscanAnalysis: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  const [showNittyGrittyDetails, setShowNittyGrittyDetails] = React.useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState<boolean>(false);
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);
  const [loadInterscanAnalysisVM]  = enhancedApi.endpoints.getApiInterscanAnalysisViewModelsById.useLazyQuery();
  const interscanAnalysisVM = useSelector((state: ApplicationState) => SelectedInterscanAnalysisVMSelector(state as ApplicationState));
  const selectedInterscanAnalysisId = useSelector((state: ApplicationState) => state.interscanAnalysis.edit.viewedInterscanAnalysisId);
  const [deleteInterscanAnalysis] = useDeleteApiInterscanAnalysesByIdMutation({ fixedCacheKey: 'shared-interscan-analysis-delete' });
  const dispatch = useDispatch();
  const params = useParams<{ slug: string }>();
  const theme = useTheme();

  const autoRefreshTimer = useRef<NodeJS.Timer | undefined>(undefined);

  const refetchInterscanAnalysis = () => {
    if(selectedInterscanAnalysisId) {
      loadInterscanAnalysisVM({id: selectedInterscanAnalysisId});
    }
  }

  const forceRefresh = () => {
    refetchInterscanAnalysis();
  }

  const createAutoRefreshTimer = () => {
    console.log("Creating auto-refresh timer for interscan analysis");
    autoRefreshTimer.current = setInterval(() => {
      console.log("Auto-refreshing interscan analysis");
      forceRefresh();
    }, AiqConfigurations.scanStatusRefreshIntervalSecs * 1000);
    return () => clearInterval(autoRefreshTimer.current);
  };

  const destroyAutoRefreshTimer = () => {
    if (autoRefreshTimer.current) {
      console.log("Destroying auto-refresh timer for interscan analysis");
      clearInterval(autoRefreshTimer.current);
      autoRefreshTimer.current = undefined;
    }
  };

  const resetAutoRefreshTimer = (refreshImmediately: boolean = false) => {
    destroyAutoRefreshTimer();
    if (refreshImmediately) {
      forceRefresh();
    }
    return createAutoRefreshTimer();
  };

  React.useEffect(() => {
    let analysisIdFromUrl = params.slug;
    if(analysisIdFromUrl) {
      dispatch(setViewedInterscanAnalysis(analysisIdFromUrl));
    }

    // createAutoRefreshTimer();

    return () => {
      destroyAutoRefreshTimer();
    };
  }, []);

  React.useEffect(() => {
    resetAutoRefreshTimer(true);
  }, [selectedInterscanAnalysisId]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteClicked = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    let id = interscanAnalysisVM?.interscanAnalysis.id as string;
    setSnackbarText(`Deleting Interscan Analysis ${id}`);
    deleteInterscanAnalysis({ id: id });
    navigate(`${NavigationConstants.interscanProcessingPath}?studyId=${interscanAnalysisVM?.study.id}`);
  };

  const renderDeleteConfirmationModal = () => (
    <ConfirmationDialog
      open={deleteConfirmationOpen}
      onCancel={() => setDeleteConfirmationOpen(false)}
      onConfirm={() => handleDeleteConfirmed()}
      confirmButtonLabel="Delete"
      title="Delete Interscan Analysis"
      text={`Do you want to delete Interscan Analysis ${interscanAnalysisVM?.interscanAnalysis.id}
      for patient ${interscanAnalysisVM?.sourceScanAnalysisGroups[0][0]?.patient.patientIdentifier}
      at timepoint ${interscanAnalysisVM?.sourceScanAnalysisGroups[0][0]?.timepoint.timepointName}?
      vs.
      patient ${interscanAnalysisVM?.sourceScanAnalysisGroups[0][1]?.patient.patientIdentifier}
      at timepoint ${interscanAnalysisVM?.sourceScanAnalysisGroups[0][1]?.timepoint.timepointName}?`}
    ></ConfirmationDialog>
  );

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          color: "#fff",
        }}
        open={!interscanAnalysisVM}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {interscanAnalysisVM && (
        <React.Fragment>
          <Grid data-cy="InterscanAnalysis" container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{ mr: 1}} gutterBottom>
                <IconButton onClick={() => handleBack()} color="primary" size="large">
                  {" "}
                  <ArrowBack />
                </IconButton>
              </Typography>
              <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography
                      variant="h5"
                      sx={{mr: 1}}
                      gutterBottom
                    >
                      Interscan Analysis
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      sx={{mr: 1}}
                      gutterBottom
                    >
                     {interscanAnalysisVM.interscanAnalysis.id}
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-end"
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{textAlign: "right" as "right",
                  marginRight: "2px",
                  fontSize: 14,
                  color: theme.palette.primary.light
                  }}>
                  Show All Details:
                </Typography>
                <Switch
                  data-cy="ShowAllDetailsButton"
                  size="small"
                  checked={showNittyGrittyDetails}
                  onChange={(event) => {
                    setShowNittyGrittyDetails(event.target.checked);
                  }}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />{" "}
                <AiqTooltip title="Delete Interscan Analysis">
                  <IconButton
                    data-cy="DeleteScanAnalysisButton"
                    onClick={() => handleDeleteClicked()}
                    sx={{ ml:3, mr: 2}}
                    size="large">
                    <Delete />
                  </IconButton>
                </AiqTooltip>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <InterscanAnalysisDetails
            interscanAnalysisVM={interscanAnalysisVM}
            showNittyGrittyDetails={showNittyGrittyDetails}
          />
          <ScanAnalysisSteps
            scanAnalysisVM={interscanAnalysisVM}
            showNittyGrittyDetails={showNittyGrittyDetails}
            onForceRefresh={() => refetchInterscanAnalysis()}
          />
          <Toast
            open={snackbarText !== undefined}
            onClose={() => setSnackbarText(undefined)}
            text={snackbarText ?? ''}
          />
          {renderDeleteConfirmationModal()}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
