import { Timepoint } from "../../redux/services/aiq-api";
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container
} from "@mui/material";
import React from "react";

interface ITimepointListProps {
  timepoints: Array<Timepoint>;
}

/**
 * A functional component to display a list of Timepoints
 * @param props The input props holding the collection of Timepoints to list
 */
export const TimepointList: React.FunctionComponent<ITimepointListProps> = (
  props: ITimepointListProps
) => {
  return (
    <Container style={{marginLeft: 0}}>
    <Typography>Timepoints</Typography>
    <TableContainer component={Paper} style={{ maxHeight: "100%", maxWidth: 300 }}>
      <Table stickyHeader aria-labelledby="tableLabel">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Sequence</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.timepoints.map((timepoint: Timepoint) => (
            <TableRow key={timepoint.id}>
              <TableCell>{timepoint.timepointName}</TableCell>
              <TableCell>{timepoint.sequence}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
};
