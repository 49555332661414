import { createReducer, ActionType } from "typesafe-actions";
import { setViewedStudyIdAction } from "../actions/StudyActions";
import { Reducer } from "redux";
import { StudyState } from "../states/StudyState";
import produce from "immer";

/**
 * This reducer handles actions pertaining to Studies and returns an updated Redux store
 * @param state - The current state of the Studies store
 * @param action - The incoming action
 */
export const studyReducer: Reducer<
  StudyState,
  | ActionType<typeof setViewedStudyIdAction>
> = createReducer<
  StudyState,
  | ActionType<typeof setViewedStudyIdAction>
>({
  edit: {
    viewedStudyId: undefined,
  },
} as StudyState)
  .handleAction(setViewedStudyIdAction, (previousState: StudyState, action) => {
    return produce(previousState, (newState) => {
      newState.edit.viewedStudyId = action.payload;
    });
  });
