import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Paper,
  Typography,
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
  IconButton,
  Grid,
  Modal,
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { CloudUpload, ExpandMore } from "@mui/icons-material";
import * as React from "react";
import {
  InterscanApplicationTaskTemplate,
  ScanAnalysisStepArtifactViewModel,
  SingleScanApplicationTaskTemplate,
} from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { AiqTooltip } from "../common/AiqToolTip";
import { useSelector } from "react-redux";
import { CurrentUserIsReviewerSelector } from "../../app/selectors/authSelectors";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import { useState } from "react";
import { ArtifactSideloadPopup } from "./ArtifactSideloadPopup";
import { ClientSideBaseAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideBaseAnalysisStepViewModel";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { Toast } from "../common/Toast";
import { JsonArtifactPreviewPopup } from "./JsonArtifactPreviewPopup";
export interface IScanStepArtifactsProps {
  /**
   * The ID of the Scan Analysis Step to display artifacts for
   */
  showNittyGrittyDetails: boolean;
  step: ClientSideBaseAnalysisStepViewModel<
    InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate
  >;
  scanAnalysis: ClientSideSingleScanAnalysisViewModel | ClientSideInterscanAnalysisViewModel;
}

function artifactIsImage(artifact: ScanAnalysisStepArtifactViewModel): boolean {
  const isImage =
    artifact.fileName !== undefined &&
    artifact.fileName !== null &&
    (artifact.fileName.indexOf(".png") > 0 || artifact.fileName.indexOf(".jpg") > 0);
  return isImage;
}


/**
 * A component that displays the output artifacts associated with a Scan Analysis or Interscan Analysis Step
 * @param props Input props for the component
 */
export const ScanStepArtifacts: React.FunctionComponent<IScanStepArtifactsProps> = (props) => {
  const { data, error, isLoading, refetch } =
    enhancedApi.endpoints.getApiScanAnalysisStepsByIdArtifactUrls.useQuery(
      { id: props.step.scanAnalysisStep.id! },
      { refetchOnMountOrArgChange: 600 }
    );
  const userIsReviewer: boolean = useSelector((state: ApplicationState) =>
    CurrentUserIsReviewerSelector(state)
  );
  const [sideloadSelection, setSideloadSelection] = useState<ScanAnalysisStepArtifactViewModel | undefined>(
    undefined
  );
  const [jsonPreviewSelection, setJsonPreviewSelection] = useState<ScanAnalysisStepArtifactViewModel | undefined>(
    undefined
  );
  const [imagePreviewSelection, setImagePreviewSelection] = useState<ScanAnalysisStepArtifactViewModel | undefined>(
    undefined
  );
  const [snackbarText, setSnackbarText] = React.useState<string | undefined>(undefined);

  const renderSideloadModal = () => {
    return (
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          maxHeight: "100%",
        }}
        open={sideloadSelection != undefined}
        onClose={() => setSideloadSelection(undefined)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box data-cy="artifact_side_load_popup" sx={{ outline: "none", maxHeight: "100%" }}>
          <ArtifactSideloadPopup
            onClose={(result: string, success: boolean) => {
              setSideloadSelection(undefined);
              setSnackbarText(result);
              if (success) {
                refetch();
              }
            }}
            artifact={sideloadSelection!}
            step={props.step}
            scanAnalysis={props.scanAnalysis}
          />
        </Box>
      </Modal>
    );
  };

  const renderJsonPreviewloadModal = () => {
    return (
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          maxHeight: "100%",
        }}
        open={jsonPreviewSelection != undefined}
        onClose={() => setJsonPreviewSelection(undefined)}
        aria-labelledby="json-preview-modal"
        aria-describedby="preview of json artifact content"
      >
        <Box data-cy="json_preview_popup" sx={{ outline: "none", maxHeight: "100%" }}>
          <JsonArtifactPreviewPopup
            onClose={() => {
              setJsonPreviewSelection(undefined);
            }}
            artifact={jsonPreviewSelection!}
          />
        </Box>
      </Modal>
    );
  };

  const renderImagePreviewloadModal = () => {
    return (
      imagePreviewSelection && (
        <Dialog
          open={imagePreviewSelection != undefined}
          onClose={() => setImagePreviewSelection(undefined)}
          maxWidth="xl"
          aria-labelledby="image-preview-modal"
          aria-describedby="artifact image preview"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
            title={
              (props.showNittyGrittyDetails
                ? imagePreviewSelection!.relativePath
                : imagePreviewSelection!.fileName) ?? ""
            }
          >
            {props.showNittyGrittyDetails
              ? imagePreviewSelection!.relativePath
              : imagePreviewSelection!.fileName}
          </DialogTitle>
          <img
            src={imagePreviewSelection!.temporaryUri!}
            title={
              (props.showNittyGrittyDetails
                ? imagePreviewSelection!.relativePath
                : imagePreviewSelection!.fileName) ?? ""
            }
            alt={
              (props.showNittyGrittyDetails
                ? imagePreviewSelection!.relativePath
                : imagePreviewSelection!.fileName) ?? ""
            }
            style={{ overflow: "hidden", objectFit: "contain" }}
          />
          <DialogActions>
            <Button onClick={() => setImagePreviewSelection(undefined)} variant="contained" color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  };

  return (
    <Paper style={{ width: "100%" }} elevation={0}>
      {renderSideloadModal()}
      {renderJsonPreviewloadModal()}
      {renderImagePreviewloadModal()}
      <Accordion data-cy="single-scan-step-artifact-accordian">
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          {error && <Typography>Failed to load step artifacts</Typography>}
          {isLoading && <CircularProgress size={20} />}
          {data && <Typography> ({data.length}) Artifacts</Typography>}
        </AccordionSummary>
        <AccordionDetails>
          {data && (
            <Box
              data-cy="single-scan-step-artifacts"
              style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}
            >
              {data
                .filter((artifact: ScanAnalysisStepArtifactViewModel) => !artifactIsImage(artifact))
                .map((artifact: ScanAnalysisStepArtifactViewModel) => (
                  <Grid container key={artifact.fileName} alignItems="center">
                    <Grid item>
                      {userIsReviewer && (
                        <AiqTooltip title="Replace artifact with side-loaded file">
                          <IconButton
                            data-cy="side-load-icon-button"
                            color="primary"
                            aria-label="Add"
                            size="medium"
                            onClick={() => setSideloadSelection(artifact)}
                          >
                            <CloudUpload
                              fontSize="large"
                              style={{ fontSize: "13px", fontWeight: "lighter" }}
                            />
                          </IconButton>
                        </AiqTooltip>
                      )}
                    </Grid>
                    <Grid item>
                      {artifact.temporaryUri && (
                        <AiqTooltip title={artifact.relativePath ?? ""}>
                          <Link href={artifact.temporaryUri} underline="hover">
                            {props.showNittyGrittyDetails ? artifact.relativePath : artifact.fileName}
                          </Link>
                        </AiqTooltip>
                      )}
                      {!artifact.temporaryUri && (
                        <Box display="flex" alignItems="center">
                          <AiqTooltip title={artifact.relativePath ?? ""}>
                            <Typography color="secondary.dark">
                              {props.showNittyGrittyDetails ? artifact.relativePath : artifact.fileName}
                            </Typography>
                          </AiqTooltip>
                          <Typography variant="subtitle2" color="primary" sx={{ ml: 1 }}>
                            {" "}
                            - <b>Expected artifact does not exist!</b>
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid item>
                    {artifact.temporaryUri && artifact.relativePath?.endsWith("json") && (
                        <AiqTooltip title={`Preview text artifact` ?? ""}>
                          <IconButton onClick={() => setJsonPreviewSelection(artifact)}>
                            <TextSnippetIcon 
                              color="primary"
                              style={{ fontSize: "14px", fontWeight: "lighter" }}
                              />
                          </IconButton>
                        </AiqTooltip>
                      )}
                    </Grid>
                  </Grid>
                ))}
              <ImageList cols={2} rowHeight={700}>
                {data
                  .filter((artifact: ScanAnalysisStepArtifactViewModel) => artifactIsImage(artifact))
                  .map(
                    (artifact: ScanAnalysisStepArtifactViewModel) =>
                      artifact.temporaryUri && (
                        <ImageListItem key={artifact.temporaryUri}>
                          <img src={artifact.temporaryUri} 
                            title={(props.showNittyGrittyDetails ? artifact.relativePath : artifact.fileName) ?? ""} 
                            alt={(props.showNittyGrittyDetails ? artifact.relativePath : artifact.fileName) ?? ""} 
                            onClick={() => setImagePreviewSelection(artifact)}
                            style={{overflow: "hidden", objectFit: "contain"}}
                            />
                          <ImageListItemBar
                            title={props.showNittyGrittyDetails ? artifact.relativePath : artifact.fileName}
                            actionIcon={
                              userIsReviewer && (
                                <AiqTooltip title="Replace artifact with side-loaded file">
                                  <IconButton
                                    data-cy="side-load-icon-button"
                                    color="primary"
                                    aria-label="Add"
                                    size="medium"
                                    onClick={() => setSideloadSelection(artifact)}
                                  >
                                    <CloudUpload
                                      fontSize="large"
                                      style={{ fontSize: "13px", fontWeight: "lighter" }}
                                    />
                                  </IconButton>
                                </AiqTooltip>
                              )
                            }
                            actionPosition="left"
                          />
                        </ImageListItem>
                      )
                  )}
              </ImageList>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <Toast
        open={snackbarText !== undefined}
        onClose={() => setSnackbarText(undefined)}
        text={snackbarText ?? ""}
        autoHideDurationMillisecs={6000}
      />
    </Paper>
  );
};
